
export default (state = {
    event: null,
    loading: false
}, action) => {
    switch (action.type) {
        case "EVENT_SET_LOADING":
            return {
                ...state,
                loading: action.loading
            };
        case "EVENT_SET_EVENT":
            return {
                ...state,
                event: action.event
            };
        default:
            return state;
    }
};
