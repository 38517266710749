import React from "react";
import PropTypes from "prop-types";
import {
    buttonActionSmall
} from "./Buttons.css";

const SmallActionButton = ({ buttonText, disabled, onClick, title }) => (
    <button
        className={buttonActionSmall}
        disabled={disabled}
        onClick={onClick}
        title={title}
    >
        {buttonText}
    </button>
);

SmallActionButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string
};

export default SmallActionButton;
