import React from "react";
import PropTypes from "prop-types";
import Loader from "../Components/Loader";
import {
    buttonAction,
    buttonActionWarning
} from "./Buttons.css";

const ActionButton = (props) => (
    <button
        disabled={props.disabled}
        className={props.warning ? buttonActionWarning : buttonAction}
        onClick={props.onClick}
        style={props.style}
        title={props.title}
    >
        {props.isLoading ?
            <Loader color={'#434f5a'}/>
            :
            <div>{props.buttonText}</div>
        }
    </button>
);

ActionButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
    title: PropTypes.string,
    warning: PropTypes.bool
};

export default ActionButton;
