import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FilterButton from "../Buttons/FilterButton";
import {
    filterContainer
} from "./Organizations.css";

class SystemFilters extends PureComponent {
    render() {
        return (
            <div className={filterContainer}>
                <FilterButton
                    active={this.props.systemFilter === "GRANADA"}
                    handleClick={() => this.props.onSetSystemFilter("GRANADA")}
                    text={"GRANADA"}
                />
                <FilterButton
                    active={this.props.systemFilter === "DRAGON"}
                    handleClick={() => this.props.onSetSystemFilter("DRAGON")}
                    text={"DRAGON"}
                />
                <FilterButton
                    active={this.props.systemFilter === "NADAG"}
                    handleClick={() => this.props.onSetSystemFilter("NADAG")}
                    text={"NADAG"}
                />
            </div>
        )
    }
}

SystemFilters.propTypes = {
    onSetSystemFilter: PropTypes.func.isRequired,
    systemFilter: PropTypes.string.isRequired
};

export default SystemFilters;
