/* eslint-disable no-undef */
import {setError} from "./errors";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const setFilter = (filter) => ({
    type: "ORGANIZATIONS_SET_FILTER",
    filter
});

const setOrganizationsIsLoading = (loading) => ({
    type: "ORGANIZATIONS_SET_LOADING",
    loading
});

const setOrganizations = (organizations) => ({
    type: "ORGANIZATIONS_SET_ORGANIZATIONS",
    organizations
});

export const setShowCreateNewOrganizationModal = (show) => ({
    type: "ORGANIZATIONS_SET_SHOW_CREATE_NEW_ORGANIZATION_MODAL",
    show
});

export const getOrganizations = (filter) => (dispatch) => new Promise(() => {
    dispatch(setOrganizationsIsLoading(true));
    dispatch(setFilter(filter));
    fetch(`${API_URL}/account/organization?service=${filter}`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setOrganizations(data));
            dispatch(setFilter(filter));
            dispatch(setOrganizationsIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            dispatch(setOrganizationsIsLoading(false));
            return error;
        })
});

export const searchOrganizations = (query) => (dispatch) => new Promise(() => {
    dispatch(setOrganizationsIsLoading(true));
    dispatch(setFilter(""));
    fetch(`${API_URL}/account/organization?query=${query}`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setOrganizations(data));
            dispatch(setOrganizationsIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            dispatch(setOrganizationsIsLoading(false));
            return error;
        })
});