
export default (state = {
    headerText: ''
}, action) => {
    switch (action.type) {
        case "SET_HEADERTEXT":
            return {
                ...state,
                headerText: action.headerText
            };
        default:
            return state;
    }
};
