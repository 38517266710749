import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {updateOrganizationOwner} from "../../../actions/organization";
import {removeModalError} from "../../../actions/errors";
import ActionButton from "../../Buttons/ActionButton";
import DefaultButton from "../../Buttons/DefaultButton";
import Modal from "../../Components/Modal";
import OtherInputWithTooltip from "../../Inputs/OtherInputWithTooltip";
import Select from "../../Components/Select";
import {
    buttonsCenter,
    error,
    errorText,
    header1,
    input1,
    updateOrganizationOwnerModalGrid
} from "./Organization.css";

class UpdateOwnerModal extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            organizationUserOptions: [],
            newOwner: null
        }
    }

    componentDidMount() {
        this.setOrganizationUserOptions();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.successUpdateCompanyOwner && this.props.successUpdateCompanyOwner) {
            this.props.onCancel();
        }
    }

    componentWillUnmount() {
        this.props.onRemoveModalError();
    }

    setOrganizationUserOptions = () => {
        let organizationUserOptions = [];
        for (const user of this.props.organizationEmployees) {
            organizationUserOptions.push({
                id: user.uuid,
                description: `${user.name ? user.name : ""} - ${user.dateOfBirth ? user.dateOfBirth : ""}`,
                value: user
            })
        }
        this.setState({organizationUserOptions: organizationUserOptions});
    };

    onOwnerChange = (option) => {
        this.setState({newOwner: option});
    };

    onUpdateOrganizationOwner = () => {
        this.props.onUpdateOrganizationOwner({
            ...this.props.organization,
            owner: this.state.newOwner.value.uuid
        })
    };

    render() {
        return (
            <div>
                <Modal show={true} type={"NORMAL"}>
                    <div className={updateOrganizationOwnerModalGrid}>

                        <h4 className={header1}>{"Endre bedriftseier"}</h4>

                        <div className={input1}>
                            <OtherInputWithTooltip
                                label={"Velg ny eier av bedrift"}
                                tooltipPlacement={"top"}
                                tooltipText={"Denne listen inneholder alle brukere som er registrert og godkjent i bedriften."}
                            >
                                <Select
                                    disabled={false}
                                    onChange={this.onOwnerChange}
                                    options={this.state.organizationUserOptions}
                                    placeholder={"Velg bruker"}
                                    value={this.state.newOwner}
                                />
                            </OtherInputWithTooltip>
                        </div>

                        <div className={error}>
                            {this.props.modalError &&
                            <div className={errorText}>{this.props.modalError}</div>
                            }
                        </div>

                        <div className={buttonsCenter}>
                            <DefaultButton
                                buttonText={"Avbryt"}
                                onClick={this.props.onCancel}
                                style={{marginRight: "12px"}}
                            />
                            <ActionButton
                                buttonText={"Oppdater"}
                                disabled={!this.state.newOwner || this.props.updateOwnerIsLoading}
                                isLoading={this.props.updateOwnerIsLoading}
                                onClick={this.onUpdateOrganizationOwner}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

UpdateOwnerModal.propTypes = {
    modalError: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onRemoveModalError: PropTypes.func.isRequired,
    onUpdateOrganizationOwner: PropTypes.func.isRequired,
    organization: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        owner: PropTypes.string.isRequired
    }).isRequired,
    organizationEmployees: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string,
        dateOfBirth: PropTypes.string
    })).isRequired,
    successUpdateCompanyOwner: PropTypes.bool,
    updateOwnerIsLoading: PropTypes.bool.isRequired
};

const mapStateToProps = ({errors, organization}) => ({
    modalError: errors.modalError,
    organization: organization.organization,
    organizationEmployees: organization.organizationEmployees,
    successUpdateCompanyOwner: organization.successUpdateCompanyOwner,
    updateOwnerIsLoading: organization.updateOwnerIsLoading
});

const mapDispatchToProps = (dispatch) => ({
    onUpdateOrganizationOwner: (organization) => {
        dispatch(updateOrganizationOwner(organization))
    },
    onRemoveModalError: () => {
        dispatch(removeModalError())
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateOwnerModal);
