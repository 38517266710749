import React from "react";
import PropTypes from "prop-types";
import { SyncLoader } from "react-spinners";

const Loader = ({ color, size=8 }) => (
    <SyncLoader
        color={color}
        size={size}
        sizeUnit={"px"}
    />
);

Loader.propTypes = {
    color: PropTypes.string.isRequired,
    size: PropTypes.number
};

export default Loader;
