
export default (state = {
    loading: false,
    users: []
}, action) => {
    switch (action.type) {
        case "USERS_SET_LOADING":
            return {
                ...state,
                loading: action.loading
            };
        case "USERS_SET_USERS":
            return {
                ...state,
                users: action.users
            };
        default:
            return state;
    }
};
