/* eslint-disable no-undef */
import {setError} from "./errors";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const setEvent = (event) => ({
    type: "EVENT_SET_EVENT",
    event
});

const setEventIsLoading = (loading) => ({
    type: "EVENT_SET_LOADING",
    loading
});

export const getEvent = (uuid) => (dispatch) => new Promise(() => {
    dispatch(setEventIsLoading(true));
    fetch(`${API_URL}/account/event/${uuid}`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setEvent(data));
            dispatch(setEventIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            dispatch(setEventIsLoading(false));
            return error;
        })
});