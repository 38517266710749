/* eslint-disable no-undef */
import {setError} from "./errors";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const setServices = (services) => ({
    type: "SERVICES_SET_SERVICES",
    services
});

export const getServices = () => (dispatch) => {
    fetch(`${API_URL}/account/services`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setServices(data));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            return error;
        })
};
