
export default (state = {
    deleteIsLoading: false,
    loading: false,
    organizations: [],
    putIsLoading: false,
    user: null
}, action) => {
    switch (action.type) {
        case "USER_SET_LOADING":
            return {
                ...state,
                loading: action.loading
            };
        case "USER_SET_ORGANIZATIONS":
            return {
                ...state,
                organizations: action.organizations
            };
        case "USER_SET_USER":
            return {
                ...state,
                user: action.user
            };
        case "USER_SET_DELETE_IS_LOADING":
            return {
                ...state,
                deleteIsLoading: action.loading
            };
        case "USER_SET_PUT_IS_LOADING":
            return {
                ...state,
                putIsLoading: action.loading
            };
        default:
            return state;
    }
};
