import React from "react";
import PropTypes from "prop-types";
import info from "../../../resources/images/info.svg";
import ReactTooltip from "react-tooltip";
import {
    tooltip,
    tooltipBox,
    tooltipIcon,
    tooltipIconBig
} from "./Tooltip.css";

const Tooltip = ({ placement, sizeBig=false, text }) => (
    <div className={tooltip}>
        <img className={sizeBig ? tooltipIconBig : tooltipIcon} data-for={text} data-tip={text} src={info} alt="tooltip" />
        <ReactTooltip
            border={false}
            className={tooltipBox}
            delayShow={100}
            id={text}
            multiline={true}
            place={placement}
            type="light"
            effect="solid"
        />
    </div>
);

Tooltip.propTypes = {
    placement: PropTypes.string.isRequired,
    sizeBig: PropTypes.bool,
    text: PropTypes.string.isRequired
};

export default Tooltip;
