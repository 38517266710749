import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import SmallActionButton from "../../Buttons/SmallActionButton";
import UpdateOwnerModal from "./UpdateOwnerModal";
import {
    card,
    cardText,
    cardWrapper,
    flexSpaceBetween,
    header2,
    input1,
    labelText,
    listLabels,
    userItemGrid,
    usersGrid
} from "./Organization.css";

class OrganizationUsers extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showUpdateOwnerModal: false
        }
    }

    toggleUpdateOwnerModal = () => {
        this.setState((prevState) => ({showUpdateOwnerModal: !prevState.showUpdateOwnerModal}));
    };

    render() {
        return (
            <div>
                <div className={usersGrid}>
                    <div className={header2}>
                        <div className={flexSpaceBetween}>
                            <h5>{"Brukere i bedriften"}</h5>
                            <SmallActionButton
                                buttonText={"Endre bedriftseier"}
                                onClick={this.toggleUpdateOwnerModal}
                            />
                        </div>
                    </div>

                    <div className={input1}>
                        <div className={listLabels}>
                            <div className={userItemGrid}>
                                <p className={labelText}>{"NAVN"}</p>
                                <p className={labelText}>{""}</p>
                                <p className={labelText}>{"STATUS"}</p>
                            </div>
                        </div>

                        {this.props.employees.map((user) => (
                            <div className={cardWrapper} key={user.uuid}>
                                <Link to={`/brukere/${user.uuid}`} style={{textDecoration: 'none', outline: 0}}>
                                    <div className={card}>
                                        <div className={userItemGrid}>
                                            <p className={cardText}>{user.name}</p>
                                            <p className={cardText} style={{color: "#ec8e7f"}}>{(this.props.organization && user.uuid === this.props.organization.owner) ? "EIER" : null}</p>
                                            <p className={cardText}>{user.status}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

                {this.state.showUpdateOwnerModal &&
                    <UpdateOwnerModal
                        onCancel={this.toggleUpdateOwnerModal}
                    />
                }
            </div>

        )
    }
}

OrganizationUsers.propTypes = {
    employees: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired
    })).isRequired,
    organization: PropTypes.shape({
        owner: PropTypes.string.isRequired
    })
};

export default OrganizationUsers;
