import React from "react";
import PropTypes from "prop-types";
import Icon from "../Components/Icon";
import {
    errorContent,
    errorIcon,
    headerContent,
    headerText,
    infoBox,
    messageContent,
    messageText,
    removeButton,
    removeIcon
} from "./Error.css";

const Information = ({ errorHeader, errorMessage, removeError }) => {
    return (
        <div className={infoBox}>
            <div className={errorContent}>
                <Icon
                    type={"informasjon"}
                    className={errorIcon}
                />
                <div className={headerContent}>
                    <div className={headerText}>
                        {errorHeader}
                    </div>
                </div>
                <div className={messageContent}>
                    <div className={messageText}>
                        {errorMessage}
                    </div>
                </div>
                <button
                    className={removeButton}
                    onClick={() => removeError()}>
                    <Icon
                        type={"kryss"}
                        className={removeIcon}
                    />
                </button>
            </div>
        </div>
    );
};

Information.propTypes = {
    errorHeader: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    removeError: PropTypes.func.isRequired
};

export default Information;
