import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setHeaderText} from "../../../actions/layout";
import {deleteUser, getOrganizations, getUser, putUser, setUser} from "../../../actions/user";
import ActionButton from "../../Buttons/ActionButton";
import DeleteButton from "../../Buttons/DeleteButton";
import Input from "../../Inputs/Input";
import InputWithTooltip from "../../Inputs/InputWithTooltip";
import LoaderBig from "../../Components/LoaderBig";
import MandatoryInput from "../../Inputs/MandatoryInput";
import OtherInputWithTooltip from "../../Inputs/OtherInputWithTooltip";
import Select from "../../Components/Select";
import Tooltip from "../../Components/Tooltip";
import UserPermissions from "./UserPermissions";
import WarningModal from "../../Components/WarningModal";
import {
    buttons,
    headerWithTooltip,
    input1,
    input2,
    input3,
    input4,
    input5,
    mainHeader,
    subHeader,
    userGrid,
    userOrganizationGrid,
    userOrganization2Grid
} from "./User.css";

class User extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            organizationOptions: [],
            organizationValue: null,
            showDeleteModal: false,
            user: {
                name: "",
                email: "",
                organization: null,
                permissions: []
            }
        }
    }

    componentDidMount() {
        this.props.onSetHeaderText("Bruker");
        this.props.onGetUser(this.props.userUuid);
        this.props.onGetOrganizations();
        this.setOrganizationOptions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user && !this.isEmpty(this.props.user)) {
            this.setUser();
        }
        if (prevProps.organizations !== this.props.organizations) {
            this.setOrganizationOptions();
        }
        if (prevProps.deleteIsLoading && !this.props.deleteIsLoading) {
            this.setState({showDeleteModal: false});
        }
    }

    componentWillUnmount() {
        this.props.onClearUser();
    }

    isEmpty = (object) => {
        for (let key in object) {
            if (Object.prototype.hasOwnProperty.call(object,key)) {
                return false;
            }
        }
        return true;
    };

    setUser = () => {
        this.setState({
            user: {
                name: this.props.user.name ? this.props.user.name : "",
                email: this.props.user.email ? this.props.user.email : "",
                organization: this.props.user.organization,
                permissions: this.props.user.permissions
            }
        });
    };

    setOrganizationOptions = () => {
        let organizationOptions = [];
        for (const organization of this.props.organizations) {
            organizationOptions.push({
                id: organization.uuid,
                description: organization.name + " (" + organization.identifier + ")",
                value: organization
            })
        }
        this.setState({organizationOptions: organizationOptions});
    };

    toggleDeleteModal = () => {
        this.setState((prevState) => ({showDeleteModal: !prevState.showDeleteModal}));
    };

    onNameChange = (e) => {
        const name = e.target.value;
        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                name: name
            }
        }));
    };

    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                email: email
            }
        }));
    };

    onOrganizationChange = (organization) => {
        this.setState((prevState) => ({
            organizationValue: organization,
            user: {
                ...prevState.user,
                organization: organization.value
            }
        }));
    };

    onDeleteUser = () => {
        this.props.onDeleteUser(this.props.user);
    };

    onPermissionChange = (checked, option) => {
        if (checked) {
            if (this.state.user.permissions.indexOf(option) === -1) {
                this.setState((prevState) => ({
                    user: {
                        ...prevState.user,
                        permissions: [...prevState.user.permissions, option]
                    }
                }));
            }
        } else {
            this.setState((prevState) => ({
                user: {
                    ...prevState.user,
                    permissions: prevState.user.permissions.filter(
                        function(permission) {
                            return permission !== option;
                        }
                    )
                }
            }));
        }
    };

    isUpdateDisabled = () => {
        return (!this.state.user.name);
    };

    onUpdateUser = () => {
        this.props.onPutUser({
            ...this.props.user,
            name: this.state.user.name,
            email: this.state.user.email,
            organization: this.state.user.organization,
            permissions: this.state.user.permissions
        })
    };

    render() {
        return (
            <div>
                {this.props.loading &&
                    <LoaderBig/>
                }

                <div className={userGrid}>
                    <div className={mainHeader}>
                        <h2>{"Bruker " + this.props.userUuid}</h2>
                    </div>

                    <div className={subHeader}>
                        {(this.props.user && this.props.user.organization && this.props.user.uuid === this.props.user.organization.owner) &&
                        <p>{"OBS: Bruker er bedriftseier!"}</p>
                        }
                    </div>

                    <div className={input1}>
                        <MandatoryInput
                            label={"Navn på bruker"}
                            maxLength={100}
                            onChange={this.onNameChange}
                            value={this.state.user.name}
                        />
                    </div>

                    <div className={input2}>
                        <Input
                            label={"E-post til bruker"}
                            maxLength={200}
                            onChange={this.onEmailChange}
                            value={this.state.user.email}
                        />
                    </div>

                    <div className={input3}>
                        <Input
                            disabled={true}
                            label={"Status"}
                            readOnly={true}
                            value={this.props.user ? this.props.user.status : ""}
                        />
                    </div>

                    <div className={input4}>
                        {(this.props.user && this.props.user.organization) ?
                            <div className={userOrganizationGrid}>
                                <div className={mainHeader}>
                                    <div className={headerWithTooltip}>
                                        <h5 style={{marginRight: "10px"}}>{"Bedrift"}</h5>
                                        <Tooltip
                                            placement={"right"}
                                            text={"Her vises bedriften som er koblet til brukeren. Det er ikke mulig å koble brukeren til en annen bedrift pga koblinger i systemet. For å bytte bedrift må brukeren slettes og brukeren må registrere seg på nytt."}
                                        />
                                    </div>
                                </div>
                                <div className={input1}>
                                    <Input
                                        disabled={true}
                                        label={"Navn"}
                                        readOnly={true}
                                        value={this.props.user ? this.props.user.organization.name : ""}
                                    />
                                </div>
                                <div className={input2}>
                                    <InputWithTooltip
                                        disabled={true}
                                        label={"Organisasjonsnummer"}
                                        readOnly={true}
                                        tooltipPlacement={"top"}
                                        tooltipText={"Dersom orgnr er noe annet enn et nummer med 9 tall, er denne bedriften ikke laget via brreg, men laget manuelt i databasen eller via dette systemet."}
                                        value={this.props.user ? this.props.user.organization.identifier : ""}
                                    />
                                </div>
                            </div>
                            :
                            <div>
                                {(this.props.user && this.props.user.status === "INSUFFICIENT") &&
                                <div className={userOrganization2Grid}>
                                    <div className={mainHeader}>
                                        <h5>{"Bedrift"}</h5>
                                    </div>
                                    <div className={input1}>
                                        <OtherInputWithTooltip
                                            label={"Koble bruker til bedrift"}
                                            tooltipPlacement={"top"}
                                            tooltipText={"Dette kan kun gjøres når brukeren ikke har bedrift fra før og status er INSUFFICIENT. Man kan ikke bytte bedrift på bruker pga gamle koblinger."}
                                        >
                                            <Select
                                                disabled={false}
                                                onChange={this.onOrganizationChange}
                                                options={this.state.organizationOptions}
                                                placeholder={"Velg bedrift"}
                                                value={this.state.organizationValue}
                                            />
                                        </OtherInputWithTooltip>
                                    </div>
                                </div>
                                }
                            </div>
                        }
                    </div>

                    <div className={input5}>
                        <UserPermissions
                            onPermissionChange={this.onPermissionChange}
                            selectedPermissions={this.state.user.permissions}
                        />
                    </div>

                    <div className={buttons}>
                        <DeleteButton
                            buttonText={"Slett bruker"}
                            deleteHandler={this.toggleDeleteModal}
                        />
                        <ActionButton
                            buttonText={"Oppdater bruker"}
                            disabled={this.isUpdateDisabled() || this.props.putIsLoading}
                            isLoading={this.props.putIsLoading}
                            onClick={this.onUpdateUser}
                            style={{width: "200px"}}
                        />
                    </div>
                </div>

                <WarningModal
                    buttonHandler={this.onDeleteUser}
                    buttonText={"Slett bruker"}
                    closeModal={this.toggleDeleteModal}
                    headerText={"Slette bruker?"}
                    isLoading={this.props.deleteIsLoading}
                    show={this.state.showDeleteModal}
                />
            </div>
        )
    }
}

User.propTypes = {
    deleteIsLoading: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onClearUser: PropTypes.func.isRequired,
    onDeleteUser: PropTypes.func.isRequired,
    onGetOrganizations: PropTypes.func.isRequired,
    onGetUser: PropTypes.func.isRequired,
    onPutUser: PropTypes.func.isRequired,
    onSetHeaderText: PropTypes.func.isRequired,
    organizations: PropTypes.array.isRequired,
    putIsLoading: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string,
        email: PropTypes.string,
        status: PropTypes.string.isRequired,
        organization: PropTypes.shape({
            identifier: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            owner: PropTypes.string.isRequired
        }),
        permissions: PropTypes.arrayOf(PropTypes.string).isRequired
    }),
    userUuid: PropTypes.string.isRequired
};

const mapStateToProps = ({user}, props) => ({
    deleteIsLoading: user.deleteIsLoading,
    loading: user.loading,
    organizations: user.organizations,
    putIsLoading: user.putIsLoading,
    user: user.user,
    userUuid: props.match.params.uuid
});

const mapDispatchToProps = (dispatch) => ({
    onClearUser: () => {
        dispatch(setUser(null))
    },
    onDeleteUser: (user) => {
        dispatch(deleteUser(user))
    },
    onGetOrganizations: () => {
        dispatch(getOrganizations())
    },
    onGetUser: (uuid) => {
        dispatch(getUser(uuid))
    },
    onPutUser: (user) => {
        dispatch(putUser(user))
    },
    onSetHeaderText: (headerText) => {
        dispatch(setHeaderText(headerText))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User);
