import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import magnifier from "../../../resources/images/magnifier.svg";
import {
    searchBox,
    searchIcon,
    searchInput
} from "./Search.css";

class Search extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            placeholder: this.props.placeholder
        }
    }

    handleOnFocus = () => {
        this.setState({ placeholder: ""});
    };

    handleOnBlur = () => {
        this.setState({placeholder: this.props.placeholder});
    };

    handleSearch = (e) => {
        const code = e.charCode || e.keyCode;
        if (code === 13 || code === undefined) {
            const searchString = e.target.value;
            this.props.handleSearch(searchString);
        }
    };

    render () {
        return (
            <div className={searchBox}>
                <img src={magnifier} alt="search" className={searchIcon} />
                <input
                    autoComplete="off"
                    className={searchInput}
                    maxLength={100}
                    onBlur={this.handleOnBlur}
                    onFocus={this.handleOnFocus}
                    onKeyPress={this.handleSearch}
                    placeholder={this.state.placeholder}
                    type="text"
                />
            </div>
        )
    }
}

Search.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired
};

export default Search;
