import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Collapse from "react-collapse";
import Icon from "../Components/Icon";
import LogoutButton from "../Buttons/LogoutButton";
import {
    adminbutton,
    adminButtonDropdown,
    namebutton,
    usericon
} from "./Layout.css";

class AdminButton extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showLogout: false
        }
    }

    toggleShowLogout = () => {
        this.setState((prevState) => ({showLogout: !prevState.showLogout}));
    };

    render() {
        return (
            <div>
                <div className={adminbutton}>
                    <button className={namebutton} onClick={this.toggleShowLogout}>
                        <Icon
                            type={"bruker"}
                            className={usericon}
                        />
                        {this.props.username}
                    </button>
                    <div className={adminButtonDropdown}>
                        <Collapse isOpened={this.state.showLogout}>
                            <div>
                                <LogoutButton
                                    style={{width: "95%"}}
                                />
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        )
    }
}

AdminButton.propTypes = {
    username: PropTypes.string.isRequired
};

export default AdminButton;
