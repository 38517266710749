import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setHeaderText} from "../../actions/layout";
import {getEvents} from "../../actions/events";
import Loader from "../Components/Loader";
import {
    flexBoxRowStart,
    loader
} from "./Log.css";
import LogTable from "./LogTable";

class Log extends PureComponent {

    componentDidMount()  {
        this.props.onSetHeaderText("Endringslogg");
        this.props.onGetEventLog();
    }

    render() {
        return (
            <div>
                <div className={flexBoxRowStart}>
                    <h2>{"Logg over alle endringer foretatt av denne løsningen"}</h2>
                    {this.props.loading &&
                    <div className={loader}>
                        <Loader color={"#434f5a"}/>
                    </div>
                    }
                </div>

                <p>
                    {"Denne loggen viser alle endringer foretatt på brukere og bedrifter siste 30 dager."}<br/>
                    {"Denne oversikten viser ikke error og feil i systemet, bare en oversikt over hvem som har endret hva."}
                </p>

                <LogTable
                    events={this.props.events}
                />
            </div>
        )
    }
}

Log.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        action: PropTypes.string,
        created: PropTypes.string,
        endpoint: PropTypes.string,
        createdBy: PropTypes.string,
        service: PropTypes.string
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    onGetEventLog: PropTypes.func.isRequired,
    onSetHeaderText: PropTypes.func.isRequired
};

const mapStateToProps = ({events}) => ({
    events: events.events,
    loading: events.loading
});

const mapDispatchToProps = (dispatch) => ({
    onGetEventLog: () => {
        dispatch(getEvents())
    },
    onSetHeaderText: (headerText) => {
        dispatch(setHeaderText(headerText))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Log);
