import React from "react";
import PropTypes from "prop-types";
import Loader from "../Components/Loader";
import {
    buttonDefault,
    buttonDefaultGrey
} from "./Buttons.css";

const DefaultButton = (props) => (
    <button
        disabled={props.isLoading}
        className={props.colorGrey ? buttonDefaultGrey : buttonDefault}
        onClick={props.onClick}
        style={props.style}
    >
        {props.isLoading ?
            <Loader color={'#78a8c2'} />
            :
            <div>{props.buttonText}</div>
        }
    </button>
);

DefaultButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    colorGrey: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object
};

export default DefaultButton;
