import React from "react";
import PropTypes from "prop-types";
import {
    inputClass,
    inputContainer,
    labelClass
} from "./Inputs.css";

const Input = ({ disabled, label, maxLength, onBlur, onChange, onKeyPress, placeholder, readOnly, required, value }) => (
    <div className={inputContainer}>
        <label htmlFor={"input" + label} className={labelClass}>{label}</label>
        <input
            autoComplete="off"
            className={inputClass}
            disabled={disabled}
            id={"input" + label}
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={onChange}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required ? required : false}
            type={"text"}
            value={value}
        />
    </div>
);

Input.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired
};

export default Input;
