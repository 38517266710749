import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import RadioButton from "../../Components/RadioButton";
import WarningTooltip from "../../Components/WarningTooltip";
import {
    flexBoxRowEnd,
    permissionCheckbox,
    tooltip
} from "./User.css";

class UserPermissions extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            permissionsView: "SIMPLE"
        }
    }

    onPermissionsViewChange = (e, option) => {
        this.setState({permissionsView: option});
    };

    onPermissionsBlobChange = (e, permissions) => {
        const checked = e.target.checked;
        for (const permission of permissions) {
            this.props.onPermissionChange(checked, permission);
        }
    };

    isBlobPermissionsChecked = (permissions) => {
        for (const permission of permissions) {
            if (this.props.selectedPermissions.indexOf(permission) === -1) {
                return false;
            }
        }
        return true;
    };

    isPermissionChecked = (permission) => {
        return this.props.selectedPermissions.indexOf(permission) !== -1;
    };

    render() {
        return (
            <div>
                <div className={flexBoxRowEnd}>
                    <h5>{"Rettigheter"}</h5>
                    <div className={tooltip}>
                        <WarningTooltip
                            placement={"bottom"}
                            text={"Vær varsom med å endre rettigheter! Disse skal helst ikke endres med mindre det står i guiden på Hjem-sida at det skal gjøres. I tillegg skal man i prinsippet aldri trenge å måtte endre på Standard brukerrettigheter eller Bedriftseierrettigheter.<br/>" +
                            "Standard brukerrettigheter: Minimale rettigheter i løsning som alle brukere får når de registrerer seg. Rettigheter til å endre seg selv og rettigheter til å søke opp bedrifter i løsningen.<br/>" +
                            "Bedriftseier: Rettigheter som tilsier at man er bedriftseier. Mulighet til å feks endre kontaktinfo til bedrift, og rettigheter til å godkjenne, avvise og slette brukere i bedriften.<br/>" +
                            "Granada kvalitetskontrollør: Rettighet til å publisere brønnskjema.<br/>" +
                            "Superbruker: Rettighet til å kunne bruke denne løsningen. Rettighet til å se og endre alle brukere og bedrifter.<br/>" +
                            "Granada: Opprette og sende inn brønnskjema.<br/>" +
                            "Dragon: Opprette og sende inn undersøkelser.<br/>" +
                            "Nadag: Opprette og sende inn prosjekt.<br/>" +
                            "Kart: Rettighet til å hente og laste kartlag.<br/>" +
                            "NGU Litteraturdatabase: Rettighet i Dragon til å koble rapport mot NGU-litteraturdatabase."}
                        />
                    </div>
                </div>

                <div style={{marginBottom: "10px"}}>
                    <RadioButton
                        active={this.state.permissionsView === "SIMPLE"}
                        buttonText={"Forenklet visning"}
                        index={0}
                        length={2}
                        option={"SIMPLE"}
                        setValue={this.onPermissionsViewChange}
                    />
                    <RadioButton
                        active={this.state.permissionsView === "ADVANCED"}
                        buttonText={"Avansert visning"}
                        index={1}
                        length={2}
                        option={"ADVANCED"}
                        setValue={this.onPermissionsViewChange}
                    />
                </div>

                {this.state.permissionsView === "SIMPLE" ?
                    <div>
                        {this.props.simplePermissions.map((simplePermission) => (
                            <div className={permissionCheckbox} key={simplePermission.name}>
                                <input
                                    checked={this.isBlobPermissionsChecked(simplePermission.permissions)}
                                    id={simplePermission.name}
                                    onChange={(e) => this.onPermissionsBlobChange(e, simplePermission.permissions)}
                                    type="checkbox"
                                />
                                <label htmlFor={simplePermission.name}>{simplePermission.name}</label>
                            </div>
                        ))}
                    </div>
                    :
                    <div>
                        {this.props.advancedPermissions.map((permission) => (
                            <div className={permissionCheckbox} key={permission}>
                                <input
                                    checked={this.isPermissionChecked(permission)}
                                    id={permission}
                                    onChange={(e) => this.props.onPermissionChange(e.target.checked, permission)}
                                    type="checkbox"
                                />
                                <label htmlFor={permission}>{permission}</label>
                            </div>
                        ))}
                    </div>
                }
            </div>
        )
    }
}

UserPermissions.propTypes = {
    advancedPermissions: PropTypes.arrayOf(PropTypes.string),
    onPermissionChange: PropTypes.func.isRequired,
    selectedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    simplePermissions: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = ({permissions}) => ({
    advancedPermissions: permissions.advancedPermissions,
    simplePermissions: permissions.simplePermissions
});

export default connect(
    mapStateToProps
)(UserPermissions);
