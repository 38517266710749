/* eslint-disable no-undef */
import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ngulogo from "../../../resources/images/ngulogo.svg";
import ActionButton from "../Buttons/ActionButton";
import ErrorMessages from "../Errors/ErrorMessages";
import {
    cardtext,
    card,
    content,
    errorWrapper,
    header,
    loginwrapper,
    logotop
} from "./Login.css";

class Login extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            showHelpModal: false
        }
    }

    handleLogin = () => {
        this.props.onClick(this.props.route);
    };

    render() {
        return (
            <div>
                <div className={logotop}>
                    <div>
                        <img src={ngulogo} alt="NGU logo" />
                    </div>
                </div>
                <div className={loginwrapper}>
                    <div className={errorWrapper}>
                        <ErrorMessages/>
                    </div>
                    <div className={content}>
                        <div className={card}>
                            <div>
                                <h1 className={header}>
                                    {"LOGG INN"}
                                </h1>
                                <p className={cardtext}>
                                    {"Bruker- og bedriftsadministrasjon for løsningene GRANADA(Brønnreg), DRAGON(Geofysikk) og NADAG(Webreg)"}
                                </p>
                                <div>
                                    <ActionButton
                                        buttonText={"Logg inn med ID-porten"}
                                        onClick={this.handleLogin}
                                        style={{width: "366px", height: "83px", marginTop: "40px", marginBottom: "10px"}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    onClick: PropTypes.func.isRequired,
    route: PropTypes.string
};

Login.defaultProps = { 
    route: `${API_URL}/service/D40E31E2-252A-4F3D-905E-10E54D9D3B8F/authentication/signin?device=web` 
};

const mapDispatchToProps = () => ({
    onClick: (route) => {
        window.location.href = route;
    }
});

export default connect(
    null,
    mapDispatchToProps
)(Login);
