import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    getInsufficientUsers,
    postOrganization
} from "../../../actions/organization";
import {removeModalError} from "../../../actions/errors";
import ActionButton from "../../Buttons/ActionButton";
import DefaultButton from "../../Buttons/DefaultButton";
import Input from "../../Inputs/Input";
import InputWithTooltip from "../../Inputs/InputWithTooltip";
import MandatoryInput from "../../Inputs/MandatoryInput";
import MandatoryInputWithTooltip from "../../Inputs/MandatoryInputWithTooltip";
import Modal from "../../Components/Modal";
import OtherInputWithTooltip from "../../Inputs/OtherInputWithTooltip";
import OtherMandatoryInputWithTooltip from "../../Inputs/OtherMandatoryInputWithTooltip";
import Select from "../../Components/Select";
import {
    buttons,
    error,
    errorText,
    header1,
    input1,
    input2,
    input3,
    input4,
    input5,
    input6,
    input7,
    newOrganizationModalGrid
} from "./NewOrganization.css";

class NewOrganization extends PureComponent {

    constructor(props) {
        super(props);

        this.nameInputRef = React.createRef();

        this.state = {
            insufficientUserOptions: [],
            newOrganization: {
                identifier: "",
                name: "",
                email: "",
                phoneNumber: "",
                address: "",
                owner: null,
                services: []
            }
        }
    }

    componentDidMount() {
        this.nameInputRef.current.focus();
        this.props.onGetInsufficientUsers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.insufficientUsers !== this.props.insufficientUsers) {
            this.setInsufficientUserOptions();
        }
        if (!prevProps.successPostNewOrganization && this.props.successPostNewOrganization) {
            this.props.onCancel();
        }
    }

    componentWillUnmount() {
        this.props.onRemoveModalError();
    }

    setInsufficientUserOptions = () => {
        let insufficientUserOptions = [];
        for (const insufficientUser of this.props.insufficientUsers) {
            insufficientUserOptions.push({
                id: insufficientUser.uuid,
                description: `${insufficientUser.name ? insufficientUser.name : ""} - ${insufficientUser.dateOfBirth ? insufficientUser.dateOfBirth : ""}`,
                value: insufficientUser
            })
        }
        this.setState({insufficientUserOptions: insufficientUserOptions});
    };

    onNameChange = (e) => {
        const name = e.target.value;
        this.setState((prevState) => ({
            newOrganization: {
                ...prevState.newOrganization,
                name: name
            }
        }));
    };

    onIdentifierChange = (e) => {
        const identifier = e.target.value;
        this.setState((prevState) => ({
            newOrganization: {
                ...prevState.newOrganization,
                identifier: identifier
            }
        }));
    };

    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState((prevState) => ({
            newOrganization: {
                ...prevState.newOrganization,
                email: email
            }
        }));
    };

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;
        this.setState((prevState) => ({
            newOrganization: {
                ...prevState.newOrganization,
                phoneNumber: phoneNumber
            }
        }));
    };

    onAddressChange = (e) => {
        const address = e.target.value;
        this.setState((prevState) => ({
            newOrganization: {
                ...prevState.newOrganization,
                address: address
            }
        }));
    };

    onOwnerChange = (option) => {
        this.setState((prevState) => ({
            newOrganization: {
                ...prevState.newOrganization,
                owner: option
            }
        }));
    };

    onServiceChange = (e, option) => {
        if (e.target.checked) {
            this.setState((prevState) => ({
                newOrganization: {
                    ...prevState.newOrganization,
                    services: [...prevState.newOrganization.services, option]
                }
            }));
        } else {
            this.setState((prevState) => ({
                newOrganization: {
                    ...prevState.newOrganization,
                    services: prevState.newOrganization.services.filter(
                        function(service) {
                            return service !== option
                        }
                    )
                }
            }));
        }
    };

    isValid = () => {
        return (
            this.state.newOrganization.name &&
            this.state.newOrganization.identifier &&
            this.state.newOrganization.services.length > 0
        );
    };

    onPostOrganization = () => {
        this.props.onPostOrganization({
            identifier: this.state.newOrganization.identifier ? this.state.newOrganization.identifier : null,
            name: this.state.newOrganization.name ? this.state.newOrganization.name : null,
            email: this.state.newOrganization.email ? this.state.newOrganization.email : null,
            phoneNumber: this.state.newOrganization.phoneNumber ? this.state.newOrganization.phoneNumber : null,
            address: this.state.newOrganization.address ? this.state.newOrganization.address : null,
            owner: this.state.newOrganization.owner ? this.state.newOrganization.owner.value.uuid : null,
            services: this.state.newOrganization.services
        })
    };

    render() {
        return (
            <div>
                <Modal show={true} type={"NORMAL"}>
                    <div className={newOrganizationModalGrid}>

                        <h4 className={header1}>{"Opprett ny bedrift"}</h4>

                        <div className={input1}>
                            <MandatoryInput
                                label={"Navn på bedrift"}
                                maxLength={100}
                                onChange={this.onNameChange}
                                value={this.state.newOrganization.name}
                                reference={this.nameInputRef}
                            />
                        </div>

                        <div className={input2}>
                            <MandatoryInputWithTooltip
                                label={"Organisasjonsnummer"}
                                maxLength={20}
                                onChange={this.onIdentifierChange}
                                tooltipPlacement={"top"}
                                tooltipText={"Organisasjonnummer kan være et faktisk orgnr eller en valgfri tekststreng. Ved å bruke valgfri tekststreng kan man feks opprette flere bedrifter innenfor samme bedrift. Dette blir da som avdelinger."}
                                value={this.state.newOrganization.identifier}
                            />
                        </div>

                        <div className={input3}>
                            <InputWithTooltip
                                label={"E-post"}
                                maxLength={200}
                                onChange={this.onEmailChange}
                                tooltipPlacement={"top"}
                                tooltipText={"Dersom denne ikke fylles ut vil eier av bedriften bli sendt til en egen side for å fylle det ut før de kan gå inn i løsningen."}
                                value={this.state.newOrganization.email}
                            />
                        </div>

                        <div className={input4}>
                            <InputWithTooltip
                                label={"Telefonnummer"}
                                maxLength={20}
                                onChange={this.onPhoneNumberChange}
                                tooltipPlacement={"top"}
                                tooltipText={"Dersom denne ikke fylles ut vil eier av bedriften bli sendt til en egen side for å fylle det ut før de kan gå inn i løsningen."}
                                value={this.state.newOrganization.phoneNumber}
                            />
                        </div>

                        <div className={input5}>
                            <Input
                                label={"Adresse"}
                                maxLength={100}
                                onChange={this.onAddressChange}
                                value={this.state.newOrganization.address}
                            />
                        </div>

                        <div className={input6}>
                            <OtherInputWithTooltip
                                label={"Eier av bedrift"}
                                tooltipPlacement={"top"}
                                tooltipText={"Denne listen inneholder alle brukere som er registrert, men som ikke har koblet seg til en bedrift enda (status=INSUFFICIENT). " +
                                "Dersom du ikke finner den som skal være eier i denne lista må du be personen registrere seg (logge inn og oppgi navn) før du kan legge inn personen som eier av bedriften."}
                            >
                                <Select
                                    disabled={false}
                                    onChange={this.onOwnerChange}
                                    options={this.state.insufficientUserOptions}
                                    placeholder={"Velg bruker"}
                                    value={this.state.newOrganization.owner}
                                />
                            </OtherInputWithTooltip>
                        </div>

                        <div className={input7}>
                            <OtherMandatoryInputWithTooltip
                                label={"Tjenester bedriften skal kunne bruke"}
                                tooltipPlacement={"top"}
                                tooltipText={"Kryss av for flere dersom bedriften skal kunne bruke flere tjenester."}
                            >
                                <div>
                                    {this.props.services.map((service) => (
                                        <div key={service}>
                                            <input
                                                checked={this.state.newOrganization.services.indexOf(service) !== -1}
                                                id={"checkbox" + service}
                                                onChange={(e) => this.onServiceChange(e, service)}
                                                type="checkbox"
                                            />
                                            <label htmlFor={"checkbox" + service}>{service}</label>
                                        </div>
                                    ))}
                                </div>
                            </OtherMandatoryInputWithTooltip>
                        </div>

                        <div className={error}>
                            {this.props.modalError &&
                            <div className={errorText}>{this.props.modalError}</div>
                            }
                        </div>

                        <div className={buttons}>
                            <DefaultButton
                                buttonText={"Avbryt"}
                                onClick={this.props.onCancel}
                                style={{marginRight: "12px"}}
                            />
                            <ActionButton
                                buttonText={"Opprett"}
                                disabled={!this.isValid() || this.props.postIsLoading}
                                isLoading={this.props.postIsLoading}
                                onClick={this.onPostOrganization}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

NewOrganization.propTypes = {
    insufficientUsers: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string,
        dateOfBirth: PropTypes.string
    })).isRequired,
    modalError: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onGetInsufficientUsers: PropTypes.func.isRequired,
    onPostOrganization: PropTypes.func.isRequired,
    onRemoveModalError: PropTypes.func.isRequired,
    postIsLoading: PropTypes.bool.isRequired,
    services: PropTypes.arrayOf(PropTypes.string).isRequired,
    successPostNewOrganization: PropTypes.bool
};

const mapStateToProps = ({errors, organization, services}) => ({
    insufficientUsers: organization.insufficientUsers,
    modalError: errors.modalError,
    postIsLoading: organization.postIsLoading,
    services: services.services,
    successPostNewOrganization: organization.successPostNewOrganization
});

const mapDispatchToProps = (dispatch) => ({
    onGetInsufficientUsers: () => {
        dispatch(getInsufficientUsers())
    },
    onPostOrganization: (organization) => {
        dispatch(postOrganization(organization))
    },
    onRemoveModalError: () => {
        dispatch(removeModalError())
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewOrganization);
