/* eslint-disable no-undef */
import {loadEmployees, setUser} from "./admin";
import {setAnnouncement, setInformation} from "./errors";
import {getAdvancedPermissions, getSimplePermissions} from "./permissions";
import {getServices} from "./services";
import {getEvents} from "./events";
import moment from "moment";
import { push } from "react-router-redux";
import { showSpinner } from "utils/loading.js";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

// This method is only called once at first rendering.
// Gets messages about maintenance of the page.
export const loadAnnouncement = () => (dispatch) => {
    fetch(`${API_URL}/service/announcements/current`, {
        method: 'GET',
        credentials: 'include'
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setAnnouncement('OBS: ' + data.message, `${moment(data.validFrom).format("DD.MM.YYYY")} - ${moment(data.validTo).format("DD.MM.YYYY")}`));
            return data;
        })
        .catch(error => {
            return error;
        })
};

// This method is only called once at first rendering.
// Handles redirecting for login.
export const loadProfile = () => (dispatch) => (
    fetch(`${API_URL}/account/me`, {
        method: 'GET',
        credentials: 'include'
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setUser(data));
            // Redirect
            if (!data.name || !data.email) {
                dispatch(push('/inngang/registrering/1'));
            } else if (data.status === "INSUFFICIENT") {
                dispatch(push('/inngang/registrering/2'));
            } else if (data.status === "REJECTED") {
                dispatch(setInformation('Du har blitt avvist av bedriften', 'Du må koble til en ny bedrift for å kunne bruke løsningen.'));
                dispatch(push('/inngang/registrering/2'));
            } else if (data.organization && data.organization.owner === data.uuid && (!data.organization.email || !data.organization.phoneNumber)) {
                dispatch(push('/inngang/registrering/3'));
            } else {
                // Set correct information and load correct data
                if (data.status !== "PENDING") {
                    if (data.organization && data.organization.owner === data.uuid) {
                        dispatch(loadEmployees(data.organization.uuid));
                    }
                    dispatch(getAdvancedPermissions());
                    dispatch(getSimplePermissions());
                    dispatch(getServices());
                    dispatch(getEvents());
                }
            }
            showSpinner(false);
            return data;
        })
        .catch(error => {
            dispatch(push('/inngang'));
            showSpinner(false);
            return error;
        })
);
