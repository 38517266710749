import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import Icon from "./Icon";
import {
    flexBoxRowEnd,
    tooltip,
    tooltipBox,
    tooltipIcon,
    tooltipText
} from "./WarningTooltip.css";

const WarningTooltip = ({ placement, text }) => (
    <div className={tooltip}>
        <div className={flexBoxRowEnd} data-for={text} data-tip={text}>
            <Icon
                type={"feilmelding"}
                className={tooltipIcon}
            />
            <p className={tooltipText}>{"OBS!"}</p>
        </div>
        <ReactTooltip
            border={false}
            className={tooltipBox}
            delayShow={100}
            id={text}
            multiline={true}
            place={placement}
            type="light"
            effect="solid"
        />
    </div>
);

WarningTooltip.propTypes = {
    placement: PropTypes.string.isRequired,
    sizeBig: PropTypes.bool,
    text: PropTypes.string.isRequired
};

export default WarningTooltip;
