
export default (state = {
    services: []
}, action) => {
    switch (action.type) {
        case "SERVICES_SET_SERVICES":
            return {
                ...state,
                services: action.services
            };
        default:
            return state;
    }
};
