
export default (state = {
    insufficientUsers: [],
    loading: false,
    organization: null,
    organizationEmployees: [],
    postIsLoading: false,
    putIsLoading: false,
    successPostNewOrganization: null,
    successUpdateCompanyOwner: null,
    updateOwnerIsLoading: false
}, action) => {
    switch (action.type) {
        case "ORGANIZATION_SET_INSUFFICIENT_USERS":
            return {
                ...state,
                insufficientUsers: action.users
            };
        case "ORGANIZATION_SET_LOADING":
            return {
                ...state,
                loading: action.loading
            };
        case "ORGANIZATION_SET_ORGANIZATION":
            return {
                ...state,
                organization: action.organization
            };
        case "ORGANIZATION_SET_ORGANIZATION_EMPLOYEES":
            return {
                ...state,
                organizationEmployees: action.organizationEmployees
            };
        case "ORGANIZATION_SET_POST_IS_LOADING":
            return {
                ...state,
                postIsLoading: action.loading
            };
        case "ORGANIZATION_SET_PUT_IS_LOADING":
            return {
                ...state,
                putIsLoading: action.loading
            };
        case "ORGANIZATION_SET_SUCCESS_POST_NEW_ORGANIZATION":
            return {
                ...state,
                successPostNewOrganization: action.value
            };
        case "ORGANIZATION_SET_SUCCESS_UPDATE_COMPANY_OWNER":
            return {
                ...state,
                successUpdateCompanyOwner: action.value
            };
        case "ORGANIZATION_SET_UPDATE_OWNER_IS_LOADING":
            return {
                ...state,
                updateOwnerIsLoading: action.loading
            };
        default:
            return state;
    }
};
