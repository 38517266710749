import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Components/Tooltip";
import {
    inputClass,
    labelClass,
    inputWithTooltipContainer,
    tooltipClass
} from "./Inputs.css";

const InputWithTooltip = ({ disabled, label, maxLength, onBlur, onChange, readOnly, required, tooltipPlacement, tooltipText, value }) => (
    <div className={inputWithTooltipContainer}>
        <label htmlFor={"input" + label} className={labelClass}>{label}</label>
        <div className={tooltipClass}>
            <Tooltip placement={tooltipPlacement} text={tooltipText}/>
        </div>
        <input
            autoComplete="off"
            className={inputClass}
            disabled={disabled}
            id={"input" + label}
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={onChange}
            readOnly={readOnly}
            required={required ? required : false}
            type={"text"}
            value={value}
        />
    </div>
);

InputWithTooltip.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    tooltipPlacement: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

export default InputWithTooltip;
