import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {
    background,
    modal,
    modalContentNormal,
    modalContentWarning
} from "./Modal.css";

class Modal extends PureComponent {

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            overflow: false
        }
    }

    componentDidUpdate() {
        if (this.ref.current) {
            let size = this.ref.current.getBoundingClientRect();
            if (size.height > window.innerHeight - 182) {
                this.setState({overflow: true});
            }
        }
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className={background}>
                <div
                    role="button" tabIndex={0}
                    className={modal}
                    onClick={function handleClick(event) {
                        event.stopPropagation();
                    }}
                >
                    <div
                        className={this.props.type === "WARNING" ? modalContentWarning : modalContentNormal}
                        ref={this.ref}
                        style={{overflow: this.state.overflow ? "scroll" : null}}
                    >
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

Modal.propTypes = {
    children: PropTypes.element.isRequired,
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
};

export default Modal;
