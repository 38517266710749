import React from "react";
import PropTypes from "prop-types";
import {
    buttonFilter,
    buttonFilterActive
} from "./Buttons.css"

const FilterButton = ({ active, handleClick, text }) => (
    <div>
        {active ?
            <button className={buttonFilterActive} onClick={() => handleClick(text)}>
                {text}
            </button>
            :
            <button className={buttonFilter} onClick={() => handleClick(text)}>
                {text}
            </button>
        }
    </div>
);

FilterButton.propTypes = {
    active: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export default FilterButton;
