
export const removeAnnouncement = () => ({
    type: "REMOVE_ANNOUNCEMENT"
});

export const removeConfirm = () => ({
    type: "REMOVE_CONFIRM"
});

export const removeError = () => ({
    type: "REMOVE_ERROR"
});

export const removeInformation = () => ({
    type: "REMOVE_INFORMATION"
});

export const removeModalError = () => ({
    type: "REMOVE_MODAL_ERROR"
});

export const setAnnouncement = (announcement, announcementMessage) => ({
    type: "SET_ANNOUNCEMENT",
    announcement: announcement,
    announcementMessage: announcementMessage
});

export const setConfirm = (confirm, confirmMessage) => ({
    type: "SET_CONFIRM",
    confirm: confirm,
    confirmMessage: confirmMessage
});

export const setError = (error, errorMessage) => ({
    type: "SET_ERROR",
    error: error,
    errorMessage: errorMessage
});

export const setInformation = (information, informationMessage) => ({
    type: "SET_INFORMATION",
    information: information,
    informationMessage: informationMessage
});

export const setModalError = (error) => ({
    type: "SET_MODAL_ERROR",
    error: error
});
