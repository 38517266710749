import React from "react";
import PropTypes from "prop-types";
import {
    inputClass,
    labelClass,
    mandatoryClass,
    mandatoryInputContainer
} from "./Inputs.css";

const MandatoryInput = ({ label, maxLength, onBlur, placeholder, onChange, reference, required, value }) => (
    <div className={mandatoryInputContainer}>
        <label htmlFor={"input" + label} className={labelClass}>{label}</label>
        <p className={mandatoryClass}>{"Obligatorisk"}</p>
        <input
            autoComplete="off"
            className={inputClass}
            id={"input" + label}
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            ref={reference}
            required={required ? required : false}
            type={"text"}
            value={value}
        />
    </div>
);

MandatoryInput.propTypes = {
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    reference: PropTypes.object,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired
};

export default MandatoryInput;
