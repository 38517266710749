import React from "react";
import PropTypes from "prop-types";
import {
    buttonRadio,
    buttonRadioActive,
    buttonRadioActiveLeftRadius,
    buttonRadioActiveRightRadius,
    buttonRadioLeftRadius,
    buttonRadioRightRadius
} from "./RadioButton.css";

const RadioButton = ({ active, buttonText, disabled, index, length, option, setValue }) => (
    <button
        className={
            active ? (
                index === 0 ?
                    buttonRadioActiveLeftRadius
                    :
                    (index === length - 1 ?
                        buttonRadioActiveRightRadius
                        :
                        buttonRadioActive
                    )
            ) : (
                index === 0 ?
                    buttonRadioLeftRadius
                    :
                    (index === length - 1 ?
                        buttonRadioRightRadius
                        :
                        buttonRadio
                    )
            )
        }
        disabled={disabled ? disabled : false}
        onClick={active ? (e) => (e.preventDefault()) : (e) => setValue(e, option)}
    >
        {buttonText}
    </button>
);

RadioButton.propTypes = {
    active: PropTypes.bool.isRequired,
    buttonText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    index: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    option: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired
};

export default RadioButton;

