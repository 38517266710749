/* global DOMAIN */

// Polyfills not included by babel
import "whatwg-fetch";

// Normalization
//import "normalize.css";

// Internal Global CSS
import "resources/fonts.css";

import { createBrowserHistory } from "history";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter, routerMiddleware, routerReducer } from "react-router-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import {reducers} from "./reducers";
import { default as Root } from "root.component";

if (DOMAIN) {
    window.postMessage('document domain', DOMAIN);
}

const history = createBrowserHistory();
const store = createStore(
    combineReducers({
        ...reducers,
        router: routerReducer
    }),
    composeWithDevTools(
        applyMiddleware(
            routerMiddleware(history),
            thunk
        )
    )
);

const element = document.getElementById("react-root");

if (element) {
    render((
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Root />
            </ConnectedRouter>
        </Provider>
    ), element);
}
