import React from "react";
import {NavLink} from "react-router-dom";
import home from "../../../resources/images/home.svg";
import log from "../../../resources/images/log.svg";
import organizations from "../../../resources/images/organizations.svg";
import users from "../../../resources/images/users.svg";
import {
    icon,
    iconWrapper,
    link,
    linkActive,
    linkContent,
    menuContainer
} from "./Layout.css";

const Menu = () => (
    <div className={menuContainer}>
        <div>
            <NavLink
                activeClassName={linkActive}
                className={link}
                exact={true}
                style={{outline: 0}}
                to={"/"}
            >
                <div className={linkContent}>
                    <div className={iconWrapper}>
                        <img className={icon} src={home} alt={"home"}/>
                    </div>
                    {"HJEM"}
                </div>
            </NavLink>
        </div>

        <div>
            <NavLink
                activeClassName={linkActive}
                className={link}
                exact={false}
                style={{outline: 0}}
                to={"/brukere"}
            >
                <div className={linkContent}>
                    <div className={iconWrapper}>
                        <img className={icon} src={users} alt={"users"}/>
                    </div>
                    {"BRUKERE"}
                </div>
            </NavLink>
        </div>

        <div>
            <NavLink
                activeClassName={linkActive}
                className={link}
                exact={false}
                style={{outline: 0}}
                to={"/bedrifter"}
            >
                <div className={linkContent}>
                    <div className={iconWrapper}>
                        <img className={icon} src={organizations} alt={"organizations"}/>
                    </div>
                    {"BEDRIFTER"}
                </div>
            </NavLink>
        </div>

        <div>
            <NavLink
                activeClassName={linkActive}
                className={link}
                exact={false}
                style={{outline: 0}}
                to={"/logg"}
            >
                <div className={linkContent}>
                    <div className={iconWrapper}>
                        <img className={icon} src={log} alt={"log"}/>
                    </div>
                    {"LOGG"}
                </div>
            </NavLink>
        </div>
    </div>
);

export default Menu;
