
export default (state = {
    filter: "GRANADA",
    loading: false,
    organizations: [],
    showCreateNewOrganizationModal: false
}, action) => {
    switch (action.type) {
        case "ORGANIZATIONS_SET_FILTER":
            return {
                ...state,
                filter: action.filter
            };
        case "ORGANIZATIONS_SET_LOADING":
            return {
                ...state,
                loading: action.loading
            };
        case "ORGANIZATIONS_SET_ORGANIZATIONS":
            return {
                ...state,
                organizations: action.organizations
            };
        case "ORGANIZATIONS_SET_SHOW_CREATE_NEW_ORGANIZATION_MODAL":
            return {
                ...state,
                showCreateNewOrganizationModal: action.show
            };
        default:
            return state;
    }
};
