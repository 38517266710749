import React from "react";
import PropTypes from "prop-types";
import ErrorMessages from "../Errors/ErrorMessages";
import {
    main,
    mainContent
} from "./Layout.css";

const Main = (props) => (
    <div className={main} id="main">
        <div className={mainContent}>
            <ErrorMessages/>
            {props.children}
        </div>
    </div>
);

Main.propTypes = {
    children: PropTypes.element.isRequired
};

export default Main;

