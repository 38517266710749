import React from "react";
import { ClipLoader } from "react-spinners";
import {
    background,
    loader
} from "./LoaderBig.css";

const LoaderBig = () => (
    <div className={background}>
        <div className={loader}>
            <ClipLoader
                color={"#111"}
                size={100}
                sizeUnit={"px"}
            />
        </div>
    </div>
);

export default LoaderBig;
