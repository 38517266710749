import React from "react";
import PropTypes from "prop-types";
import ActionButton from "../Buttons/ActionButton";
import DefaultButton from "../Buttons/DefaultButton";
import Modal from "./Modal";
import {
    modalButtons,
    modalContainer,
    modalHeader,
    modalInfo,
    modalInfoText
} from "./WarningModal.css";

const WarningModal = (props) => (
    <Modal show={props.show} type={"WARNING"}>
        <div className={modalContainer}>
            <h4 className={modalHeader}>{props.headerText}</h4>
            <div className={modalInfoText}>
                {props.infoText ?
                    <p className={modalInfo}>{props.infoText}</p>
                    :
                    null
                }
            </div>
            <div className={modalButtons}>
                <DefaultButton
                    buttonText={"Avbryt"}
                    colorGrey={true}
                    onClick={props.closeModal}
                />
                <ActionButton
                    buttonText={props.buttonText}
                    isLoading={props.isLoading}
                    onClick={props.buttonHandler}
                    warning={true}
                    style={{marginLeft: "20px"}}
                />
            </div>
        </div>
    </Modal>
);

WarningModal.propTypes = {
    buttonHandler: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    headerText: PropTypes.string.isRequired,
    infoText: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired
};

export default WarningModal;
