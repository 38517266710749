import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import deadlink from "../../../resources/images/deadlink.svg";
import ActionButton from "../Buttons/ActionButton";
import {
    background,
    container,
    containerGrid
} from "./PageNotFound.css";

class PageNotFound extends PureComponent {
    render() {
        return (
            <div className={background}>
                <div className={container}>
                    <div className={containerGrid}>
                        <div>
                            <img src={deadlink} alt="Dead link"/>
                        </div>
                        <h3>{"404"}</h3>
                        <p><b>{"Vi finner ikke siden."}</b></p>
                        <p>{"Siden kan være flyttet, slettet eller den finnes ikke."}</p>
                        <ActionButton
                            buttonText={"Gå til forside"}
                            onClick={() => this.props.onRedirectMainPage()}
                            style={{width: "321px"}}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

PageNotFound.propTypes = {
    onRedirectMainPage: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
    onRedirectMainPage: () => {
        dispatch(push("/"))
    }
});

export default connect(
    null,
    mapDispatchToProps
)(PageNotFound);
