import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setHeaderText } from "../../actions/layout";
import {
    headerWithTooltip,
    listSubText,
    listText,
    subHeader,
    subSubHeader,
    subText
} from "./Home.css";
import Tooltip from "../Components/Tooltip";

class Home extends PureComponent {

    componentDidMount ()  {
        this.props.onSetHeaderText("Informasjon om siden");
    }

    render() {
        return (
            <div>
                <h2>{"Side for administrasjon av brukere og bedrifter"}</h2>

                <p className={subText}>{"Denne siden er laget for bruker-, bedrift- og rettighetskontroll for løsningene GRANADA(Brønnreg), DRAGON(Geofysikk) og NADAG(Webreg). "}</p>
                <p className={subText}>{"Her kan du se og endre brukere og bedrifter for de tre løsningene."}</p>
                <p className={subText}>{"Under ligger forklaringer på hva man kan, og ikke kan gjøre i løsningen, samt forklaring på hvordan man utfører de mest vanlige oppgavene."}</p>
                <br/><br/><br/>

                <h3 className={subHeader}>{"Dette kan du gjøre/ikke gjøre i løsningen:"}</h3>

                <h5 className={subSubHeader}>{"Dette kan du gjøre for brukere:"}</h5>
                <ul>
                    <li>
                        <p className={listText}>{"Endre navn og epost"}</p>
                    </li>
                    <li>
                        <p className={listText}>{"Endre rettigheter"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Du kan gi brukeren en ny \"rolle\" ved å endre på rettighetene brukeren har. Her kan man endre en bruker til å bli feks kvalitetskontrollør, eller gi brukeren rettigheter til å bruke både DRAGON og NADAG."}</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className={listText}>{"Koble bruker til bedrift"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Dette kan kun gjøres for brukere som ikke allerede er koblet til en bedrift."}</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className={listText}>{"Slette bruker"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Brukerobjektet vil fortsatt finnes i databasen, men status blir satt til DELETED og personnummer blir fjernet. Dersom brukeren prøver å logge inn på nytt vil en ny bruker bli opprettet."}</p>
                            </li>
                        </ul>
                    </li>
                </ul>

                <h5 className={subSubHeader}>{"Dette kan du IKKE gjøre for brukere:"}</h5>
                <ul>
                    <li>
                        <p className={listText}>{"Opprette ny bruker"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Dette er ikke mulig fordi bruker må oppgi personnummer for innlogging via ID-porten. Brukeren må opprette seg selv først ved å logge inn i en av løsningene. Deretter er det mulig å endre på brukeren gjennom denne løsningen (feks endre navn, legge til bedrift...)"}</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className={listText}>{"Bytte bedrift"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Dette kan ikke gjøres pga historikk og koblinger. Dersom en bruker skal endre bedrift må brukeren først slettes, deretter må brukeren registrere seg inn på nytt ved å logge inn, og velge ny bedrift."}</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className={listText}>{"Endre status"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Du kan ikke direkte endre statusen til brukere gjennom denne løsningen. (Feks godkjenne / avslå brukere til bedrift, eller gjøre en slettet bruker til aktiv igjen). Her er forklaring på de forskjellige statuser en bruker kan ha og hva de betyr:"}</p>
                            </li>
                            <li>
                                <p className={listSubText}>{"INSUFFICIENT: Brukere som har logget inn, men som mangler navn eller bedrift"}</p>
                            </li>
                            <li>
                                <p className={listSubText}>{"PENDING: Brukere som har koblet seg mot en bedrift, men som ikke har blitt godkjent enda"}</p>
                            </li>
                            <li>
                                <p className={listSubText}>{"REJECTED: Brukere som har blitt avvist av bedrift. Disse får da mulighet til å koble seg mot en bedrift på nytt"}</p>
                            </li>
                            <li>
                                <p className={listSubText}>{"APPROVED: Brukere som har blitt godkjent i bedrift"}</p>
                            </li>
                            <li>
                                <p className={listSubText}>{"DELETED: Brukere som er slettet"}</p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <br/>

                <h5 className={subSubHeader}>{"Dette kan du gjøre for bedrifter:"}</h5>
                <ul>
                    <li>
                        <p className={listText}>{"Opprette ny bedrift"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Dersom bedriften skal ha en eier bør eieren først registrere seg ved å logge inn og oppgi navn."}</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className={listText}>{"Endre navn, epost, telefonnummer og adresse"}</p>
                    </li>
                    <li>
                        <p className={listText}>{"Endre bedriftseier"}</p>
                    </li>
                    <li>
                        <p className={listText}>{"Endre system/tjenester bedriften skal kunne bruke"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Dvs at en bedrift kan tilhøre feks både NADAG og DRAGON. Brukerne i bedriften må oppdaters manuelt (gjøres ved å oppdatere rettigheter for brukerne)."}</p>
                            </li>
                        </ul>
                    </li>
                </ul>

                <h5 className={subSubHeader}>{"Dette kan du IKKE gjøre for bedrifter:"}</h5>
                <ul>
                    <li>
                        <p className={listText}>{"Endre organisasjonsnummer"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Dette kan ikke gjøres pga koblinger mot brreg og fordi feltet må være unikt"}</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className={listText}>{"Sette flere bedriftseiere"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"En bedrift kan kun ha én eier og en person blir ikke bedriftseier selv om han får bedriftseierrettigheter"}</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className={listText}>{"Slette bedrift"}</p>
                        <ul>
                            <li>
                                <p className={listSubText}>{"Dette kan ikke gjøres pga koblinger i systemet"}</p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <br/><br/><br/>

                <h3 className={subHeader}>{"Noen utvalgte forklaringer:"}</h3>

                <div className={headerWithTooltip}>
                    <h5 className={subSubHeader} style={{marginRight: "10px"}}>{"Registrere ny kvalitetskontrollør (GRANADA)"}</h5>
                    <Tooltip
                        placement={"right"}
                        text={"Dette gjelder kun for GRANADA."}
                    />
                </div>
                <ol type="1">
                    <li>
                        <p>{"Be ny kvalitetskontrollør om å registrere seg (Logge inn og skrive inn navn)"}</p>
                    </li>
                    <li>
                        <p>{"Søk opp brukeren"}</p>
                    </li>
                    <li>
                        <p>{"Legg brukeren til i bedriften \"NGU (kvalitetskontrollør)\""}</p>
                    </li>
                    <li>
                        <p>{"Gi brukeren alle GRANADA-rettigheter og Kvalitetskontrollør-rettigheter"}</p>
                    </li>
                </ol>
                <br/>

                <div className={headerWithTooltip}>
                    <h5 className={subSubHeader} style={{marginRight: "10px"}}>{"Gi bruker tilgang til NGU litteraturdatabase (DRAGON)"}</h5>
                    <Tooltip
                        placement={"right"}
                        text={"Dette gjelder kun for DRAGON. Kun brukere med korrekte rettigheter kan koble rapporter opp til NGUs litteraturdatabase. Disse rettighetene må da settes via denne løsningen."}
                    />
                </div>
                <ol type="1">
                    <li>
                        <p>{"Bruker må registrer seg på vanlig måte i løsningen"}</p>
                    </li>
                    <li>
                        <p>{"Søk opp brukeren"}</p>
                    </li>
                    <li>
                        <p>{"Gi brukeren rettigheter til å søke i litteraturdatabasen"}</p>
                    </li>
                </ol>
                <br/>

                <div className={headerWithTooltip}>
                    <h5 className={subSubHeader} style={{marginRight: "10px"}}>{"Registrere ny bedrift med selvvalgt eier"}</h5>
                    <Tooltip
                        placement={"right"}
                        text={"Eks kan dette være for å legge til kommuner i løsningen hvor man ikke vil at ordfører skal stå som eier. Det kan også være for å registrere avdelinger i større bedrifter (eks Statens Vegvesen)"}
                    />
                </div>
                <ol type="1">
                    <li>
                        <p>{"Be den som skal være eier av bedrift om å registrere seg (Logge inn og skrive inn navn)"}</p>
                    </li>
                    <li>
                        <p>{"Opprett ny bedrift i denne løsningen og velg eieren fra lista"}</p>
                    </li>
                    <li>
                        <p>{"Andre brukere som skal tilhøre bedriften registrerer seg som normalt"}</p>
                    </li>
                </ol>
                <br/>

                <div className={headerWithTooltip}>
                    <h5 className={subSubHeader} style={{marginRight: "10px"}}>{"Endre eier av bedrift"}</h5>
                    <Tooltip
                        placement={"right"}
                        text={"Den som er satt til eier av bedrift har mulighet til å godkjenne nye brukere, samt administrere alle brukere i bedriften."}
                    />
                </div>
                <ol type="1">
                    <li>
                        <p>{"Den som skal bli ny eier må allerede være registrert og godkjent i bedriften"}</p>
                    </li>
                    <li>
                        <p>{"Søk opp bedriften og trykk på knappen \"Endre bedriftseier\""}</p>
                    </li>
                    <li>
                        <p>{"Velg fra lista den som skal være ny eier av bedrift"}</p>
                    </li>
                </ol>
                <br/>

                <div className={headerWithTooltip}>
                    <h5 className={subSubHeader} style={{marginRight: "10px"}}>{"Gi en bedrift tilgang til flere tjenester"}</h5>
                    <Tooltip
                        placement={"right"}
                        text={"Eks kan dette være for at samme bedrift skal kunne registrere undersøkelser i både NADAG og DRAGON. " +
                        "Det er her mulig å gi individuelle rettigheter til brukerne av bedriften. Dvs at bedriften kan bruke begge løsningene, men brukere i bedriften kan være registrert i kun en av de (eller begge). " +
                        "Slik kan en bedrift ha et \"team\" i NADAG og et i DRAGON, selv om de har samme bedrift med samme eier."}
                    />
                </div>
                <ol type="1">
                    <li>
                        <p>{"Gå til bedriften og gi den tilgang til ønskede tjenester"}</p>
                    </li>
                    <li>
                        <p>{"Gå til eier av bedriften og gi personen rettigheter til alle valgte tjenester"}</p>
                    </li>
                    <li>
                        <p>{"Gjenta steg 2 for alle brukere i bedriften som skal ha tilgang til valgte tjenester"}</p>
                    </li>
                </ol>
            </div>
        )
    }
}

Home.propTypes = {
    onSetHeaderText: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
    onSetHeaderText: (headerText) => {
        dispatch(setHeaderText(headerText))
    }
});

export default connect(
    null,
    mapDispatchToProps
)(Home);
