/* eslint-disable no-undef */
import {setError} from "./errors";


function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const setEmployees = (data) => ({
    type: 'SET_EMPLOYEES',
    data
});

export const setUser = (data) => ({
    type: 'SET_USER',
    data
});

export const loadEmployees = (uuid) => (dispatch) => {
    fetch(`${API_URL}/account/organization/${uuid}/user`, {
        method: 'GET',
        credentials: 'include'
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                } else if (nameA > nameB) {
                    return 1;
                } else {
                    return 0;
                }
            });
        })
        .then(sortedData => {
            dispatch(setEmployees(sortedData));
            return sortedData;
        })
        .catch(error => {
            dispatch(setError('Det har skjedd en feil', 'Prøv å last siden på nytt.'));
            return error;
        })
};
