import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Collapse from "react-collapse";
import arrow from "../../../resources/images/arrow.svg";
import {
    arrowIcon,
    chosenText,
    dropdown,
    dropdownContent,
    dropdownDisabled,
    dropdownLabel,
    flexBox,
    optionText,
    placeholder
} from "./Select.css";

class Select extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({isOpen: false});
        }
    };

    onToggleShowDropdown = (e) => {
        const code = e.charCode || e.keyCode;
        if (code === 40 || code === undefined) {
            if (this.props.options.length > 0 || this.state.isOpen) {
                this.setState((prevState) => ({isOpen: !prevState.isOpen}));
            }
        }
    };

    onPickOption = (e, option) => {
        const code = e.charCode || e.keyCode;
        if (code === 13 || code === undefined) {
            this.props.onChange(option);
            this.setState({isOpen: false});
        }
    };

    getOptionFromValue = (id) => {
        let option = this.props.options.find(option => option.id === id);
        return option.description;
    };

    render() {
        return (
            <div ref={this.setWrapperRef}>
                {this.props.disabled ?
                    <div className={dropdownDisabled}>
                        <div className={flexBox}>
                            <p className={placeholder}>{this.props.placeholder}</p>
                            <img src={arrow} alt="arrow" className={arrowIcon} />
                        </div>
                    </div>
                    :
                    <div role="button" tabIndex="0" className={dropdownLabel} onClick={this.onToggleShowDropdown} onKeyDown={this.onToggleShowDropdown}>
                        <div className={flexBox}>
                            {this.props.value ?
                                <p className={chosenText}>{this.getOptionFromValue(this.props.value.id)}</p>
                                :
                                <p className={placeholder}>{this.props.placeholder}</p>
                            }
                            <img src={arrow} alt="arrow" className={arrowIcon} />
                        </div>
                    </div>
                }
                <div className={dropdown}>
                    <Collapse isOpened={this.state.isOpen}>
                        <div className={dropdownContent}>
                            {this.props.options.map((option) => (
                                <div key={"select" + option.id + option.description}>
                                    <div
                                        className={optionText}
                                        role="button"
                                        tabIndex="0"
                                        onClick={(e) => this.onPickOption(e, option)}
                                        onKeyDown={(e) => this.onPickOption(e, option)}
                                    >
                                        <p>{option.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }
}

Select.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        value: PropTypes.object.isRequired
    })).isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.object
};

export default Select;
