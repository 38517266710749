/* eslint-disable no-undef */
import {removeModalError, setConfirm, setError, setModalError} from "./errors";
import {setShowCreateNewOrganizationModal} from "./organizations";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const setInsufficientUsers = (users) => ({
    type: "ORGANIZATION_SET_INSUFFICIENT_USERS",
    users
});

const setOrganizationIsLoading = (loading) => ({
    type: "ORGANIZATION_SET_LOADING",
    loading
});

export const setOrganization = (organization) => ({
    type: "ORGANIZATION_SET_ORGANIZATION",
    organization
});

export const setOrganizationEmployees = (organizationEmployees) => ({
    type: "ORGANIZATION_SET_ORGANIZATION_EMPLOYEES",
    organizationEmployees
});

const setPostOrganizationIsLoading = (loading) => ({
    type: "ORGANIZATION_SET_POST_IS_LOADING",
    loading
});

const setPutOrganizationIsLoading = (loading) => ({
    type: "ORGANIZATION_SET_PUT_IS_LOADING",
    loading
});

const setSuccessPostNewOrganization = (value) => ({
    type: "ORGANIZATION_SET_SUCCESS_POST_NEW_ORGANIZATION",
    value
});

const setSuccessUpdateCompanyOwner = (value) => ({
    type: "ORGANIZATION_SET_SUCCESS_UPDATE_COMPANY_OWNER",
    value
});

const setUpdateOwnerIsLoading = (loading) => ({
    type: "ORGANIZATION_SET_UPDATE_OWNER_IS_LOADING",
    loading
});

export const getOrganization = (uuid) => (dispatch) => new Promise(() => {
    dispatch(setOrganizationIsLoading(true));
    fetch(`${API_URL}/account/organization/${uuid}`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setOrganization(data));
            dispatch(setOrganizationIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            dispatch(setOrganizationIsLoading(false));
            return error;
        })
});

export const getOrganizationEmployees = (uuid) => (dispatch) => new Promise(() => {
    fetch(`${API_URL}/account/organization/${uuid}/user`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setOrganizationEmployees(data));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            return error;
        })
});

export const postOrganization = (organization) => (dispatch) => new Promise(() => {
    dispatch(removeModalError());
    dispatch(setPostOrganizationIsLoading(true));
    fetch(`${API_URL}/account/organization`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(organization)
    })
        .then(response => {
            if (!response.ok) {
                if (response.status === 409) {
                    dispatch(setModalError("Bedrift med samme orgnr finnes fra før. Prøv igjen med nytt orgnr."));
                } else {
                    dispatch(setModalError("Det har skjedd en ukjent feil ved oppretting av bedrift. Prøv igjen eller meld fra om feil."));
                }
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            dispatch(setOrganization(data));
            dispatch(setConfirm("Ny bedrift er opprettet", `${organization.name} er nå opprettet`));
            dispatch(setShowCreateNewOrganizationModal(false));
            dispatch(setSuccessPostNewOrganization(true));
            setTimeout(() => dispatch(setSuccessPostNewOrganization(null)), 500);
            dispatch(setPostOrganizationIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setPostOrganizationIsLoading(false));
            return error;
        })
});

export const putOrganization = (organization) => (dispatch) => new Promise(() => {
    dispatch(setPutOrganizationIsLoading(true));
    fetch(`${API_URL}/account/organization/${organization.uuid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(organization)
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setOrganization(data));
            dispatch(setConfirm("Bedriften er oppdatert", `Bedrift ${organization.name} er oppdatert`));
            dispatch(setPutOrganizationIsLoading(false));
            dispatch(getOrganizationEmployees(organization.uuid));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en ukjent feil", "Prøv igjen eller meld fra om feil"));
            dispatch(setPutOrganizationIsLoading(false));
            return error;
        })
});

export const getInsufficientUsers = () => (dispatch) => {
    fetch(`${API_URL}/account/user?status=INSUFFICIENT`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setInsufficientUsers(data));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            return error;
        })
};

export const updateOrganizationOwner = (organization) => (dispatch) => new Promise(() => {
    dispatch(removeModalError());
    dispatch(setUpdateOwnerIsLoading(true));
    fetch(`${API_URL}/account/organization/${organization.uuid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(organization)
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setOrganization(data));
            dispatch(setConfirm("Bedriftseier er oppdatert", `Bedriftseier for ${organization.name} er oppdatert`));
            dispatch(setSuccessUpdateCompanyOwner(true));
            setTimeout(() => dispatch(setSuccessUpdateCompanyOwner(null)), 500);
            dispatch(setUpdateOwnerIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setModalError("Det har skjedd en ukjent feil ved endring av bedriftseier. Prøv igjen eller meld fra om feil."));
            dispatch(setUpdateOwnerIsLoading(false));
            return error;
        })
});
