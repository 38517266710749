import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Components/Tooltip";
import {
    inputClass,
    labelClass,
    mandatoryClass,
    mandatoryInputWithTooltipContainer,
    tooltipClass
} from "./Inputs.css";

const MandatoryInputWithTooltip = ({ label, maxLength, onChange, tooltipPlacement, tooltipText, value }) => (
    <div className={mandatoryInputWithTooltipContainer}>
        <label htmlFor={"input" + label} className={labelClass}>{label}</label>
        <p className={mandatoryClass}>{"Obligatorisk"}</p>
        <div className={tooltipClass}>
            <Tooltip placement={tooltipPlacement} text={tooltipText}/>
        </div>
        <input
            autoComplete="off"
            className={inputClass}
            id={"input" + label}
            maxLength={maxLength}
            onChange={onChange}
            type={"text"}
            value={value}
        />
    </div>
);

MandatoryInputWithTooltip.propTypes = {
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    tooltipPlacement: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

export default MandatoryInputWithTooltip;
