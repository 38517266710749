import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setHeaderText} from "../../../actions/layout";
import {
    getInsufficientUsers,
    getOrganization,
    getOrganizationEmployees,
    putOrganization,
    setOrganization,
    setOrganizationEmployees
} from "../../../actions/organization";
import ActionButton from "../../Buttons/ActionButton";
import Input from "../../Inputs/Input";
import InputWithTooltip from "../../Inputs/InputWithTooltip";
import LoaderBig from "../../Components/LoaderBig";
import MandatoryInput from "../../Inputs/MandatoryInput";
import OrganizationUsers from "./OrganizationUsers";
import OtherInputWithTooltip from "../../Inputs/OtherInputWithTooltip";
import OtherMandatoryInputWithTooltip from "../../Inputs/OtherMandatoryInputWithTooltip";
import Select from "../../Components/Select";
import {
    buttons,
    header1,
    input1,
    input2,
    input3,
    input4,
    input5,
    input6,
    input7,
    organizationGrid
} from "./Organization.css";

class Organization extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            insufficientUserOptions: [],
            organization: {
                name: "",
                email: "",
                phoneNumber: "",
                address: "",
                services: []
            },
            organizationOwner: null
        }
    }

    componentDidMount() {
        this.props.onSetHeaderText("Bedrift");
        this.props.onGetOrganization(this.props.organizationUuid);
        this.props.onGetOrganizationEmployees(this.props.organizationUuid);
        this.props.onGetInsufficientUsers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.organization !== this.props.organization && this.props.organization) {
            this.setOrganization();
        }
        if (prevProps.insufficientUsers !== this.props.insufficientUsers) {
            this.setInsufficientUserOptions();
        }
    }

    componentWillUnmount() {
        this.props.onClearOrganization();
        this.props.onClearOrganizationEmployees();
    }

    setInsufficientUserOptions = () => {
        let insufficientUserOptions = [];
        for (const insufficientUser of this.props.insufficientUsers) {
            insufficientUserOptions.push({
                id: insufficientUser.uuid,
                description: `${insufficientUser.name ? insufficientUser.name : ""} - ${insufficientUser.dateOfBirth ? insufficientUser.dateOfBirth : ""}`,
                value: insufficientUser
            })
        }
        this.setState({insufficientUserOptions: insufficientUserOptions});
    };

    setOrganization = () => {
        this.setState({
            organization: {
                name: this.props.organization.name ? this.props.organization.name : "",
                email: this.props.organization.email ? this.props.organization.email : "",
                phoneNumber: this.props.organization.phoneNumber ? this.props.organization.phoneNumber : "",
                address: this.props.organization.address ? this.props.organization.address : "",
                services: this.props.organization.services
            }
        });
    };

    onNameChange = (e) => {
        const name = e.target.value;
        this.setState((prevState) => ({
            organization: {
                ...prevState.organization,
                name: name
            }
        }));
    };

    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState((prevState) => ({
            organization: {
                ...prevState.organization,
                email: email
            }
        }));
    };

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;
        this.setState((prevState) => ({
            organization: {
                ...prevState.organization,
                phoneNumber: phoneNumber
            }
        }));
    };

    onAddressChange = (e) => {
        const address = e.target.value;
        this.setState((prevState) => ({
            organization: {
                ...prevState.organization,
                address: address
            }
        }));
    };

    onServiceChange = (e, option) => {
        let checked = e.target.checked;
        if (checked) {
            this.setState((prevState) => ({
                organization: {
                    ...prevState.organization,
                    services: [...prevState.organization.services, option]
                }
            }));
        } else {
            this.setState((prevState) => ({
                organization: {
                    ...prevState.organization,
                    services: prevState.organization.services.filter(
                        function(service) {
                            return service !== option;
                        }
                    )
                }
            }));
        }
    };

    onOwnerChange = (option) => {
        this.setState({organizationOwner: option});
    };

    isUpdateDisabled = () => {
        return (!this.state.organization.name || this.state.organization.services.length === 0);
    };

    onUpdateOrganization = () => {
        this.props.onPutOrganization({
            ...this.props.organization,
            name: this.state.organization.name,
            email: this.state.organization.email ? this.state.organization.email : null,
            phoneNumber: this.state.organization.phoneNumber ? this.state.organization.phoneNumber : null,
            address: this.state.organization.address ? this.state.organization.address : null,
            owner: this.state.organizationOwner ? this.state.organizationOwner.value.uuid : this.props.organization.owner,
            services: this.state.organization.services
        })
    };

    render() {
        return (
            <div>
                {this.props.loading &&
                    <LoaderBig/>
                }

                <div className={organizationGrid}>
                    <div className={header1}>
                        <h2>{"Bedrift " + this.props.organizationUuid}</h2>
                    </div>

                    <div className={input1}>
                        <Input
                            disabled={true}
                            label={"Organisasjonsnummer"}
                            readOnly={true}
                            value={this.props.organization ? this.props.organization.identifier : ""}
                        />
                    </div>

                    <div className={input2}>
                        <MandatoryInput
                            label={"Navn på bedrift"}
                            maxLength={100}
                            onChange={this.onNameChange}
                            value={this.state.organization.name}
                        />
                    </div>

                    <div className={input3}>
                        <InputWithTooltip
                            label={"E-post til bedrift"}
                            maxLength={200}
                            onChange={this.onEmailChange}
                            tooltipPlacement={"top"}
                            tooltipText={"Dersom denne ikke fylles ut vil eier av bedriften bli sendt til en egen side for å fylle det ut før de kan gå inn i løsningen."}
                            value={this.state.organization.email}
                        />
                    </div>

                    <div className={input4}>
                        <InputWithTooltip
                            label={"Telefonnummer til bedrift"}
                            maxLength={20}
                            onChange={this.onPhoneNumberChange}
                            tooltipPlacement={"top"}
                            tooltipText={"Dersom denne ikke fylles ut vil eier av bedriften bli sendt til en egen side for å fylle det ut før de kan gå inn i løsningen."}
                            value={this.state.organization.phoneNumber}
                        />
                    </div>

                    <div className={input5}>
                        <Input
                            label={"Adresse"}
                            maxLength={100}
                            onChange={this.onAddressChange}
                            value={this.state.organization.address}
                        />
                    </div>

                    <div className={input6}>
                        <OtherMandatoryInputWithTooltip
                            label={"Tjenester bedriften skal kunne bruke"}
                            tooltipPlacement={"top"}
                            tooltipText={"Kryss av for flere dersom bedriften skal kunne bruke flere tjenester. " +
                            "Bedriften vil da kunne velge hvilke tjenester nye brukere skal ha tilgang til. " +
                            "Gamle brukere må oppdateres manuelt dersom de trenger tilganger til flere tjenester."}
                        >
                            <div>
                                {this.props.services.map((service) => (
                                    <div key={service}>
                                        <input
                                            checked={this.state.organization.services.indexOf(service) !== -1}
                                            id={service}
                                            onChange={(e) => this.onServiceChange(e, service)}
                                            type="checkbox"
                                        />
                                        <label htmlFor={service}>{service}</label>
                                    </div>
                                ))}
                            </div>
                        </OtherMandatoryInputWithTooltip>
                    </div>

                    <div className={input7}>
                        {this.props.organization &&
                            <div>
                                {this.props.organization.owner ?
                                    <OrganizationUsers
                                        employees={this.props.organizationEmployees}
                                        organization={this.props.organization}
                                    />
                                    :
                                    <OtherInputWithTooltip
                                        label={"Eier av bedrift"}
                                        tooltipPlacement={"top"}
                                        tooltipText={"Denne listen inneholder alle brukere som er registrert, men som ikke har koblet seg til en bedrift enda. " +
                                        "Dersom du ikke finner den som skal være eier i denne lista må du be personen registrere seg (logge inn og oppgi navn) før du kan legge inn personen som eier av bedriften."}
                                    >
                                        <Select
                                            disabled={false}
                                            onChange={this.onOwnerChange}
                                            options={this.state.insufficientUserOptions}
                                            placeholder={"Velg bruker"}
                                            value={this.state.organizationOwner}
                                        />
                                    </OtherInputWithTooltip>
                                }
                            </div>
                        }
                    </div>

                    <div className={buttons}>
                        <ActionButton
                            buttonText={"Oppdater bedrift"}
                            disabled={this.isUpdateDisabled() || this.props.putIsLoading}
                            isLoading={this.props.putIsLoading}
                            onClick={this.onUpdateOrganization}
                            style={{width: "200px"}}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

Organization.propTypes = {
    insufficientUsers: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string,
        dateOfBirth: PropTypes.string
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    onClearOrganization: PropTypes.func.isRequired,
    onClearOrganizationEmployees: PropTypes.func.isRequired,
    onGetInsufficientUsers: PropTypes.func.isRequired,
    onGetOrganization: PropTypes.func.isRequired,
    onGetOrganizationEmployees: PropTypes.func.isRequired,
    onPutOrganization: PropTypes.func.isRequired,
    onSetHeaderText: PropTypes.func.isRequired,
    organization: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        identifier: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        address: PropTypes.string,
        owner: PropTypes.string,
        services: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    }),
    organizationEmployees: PropTypes.array.isRequired,
    organizationUuid: PropTypes.string.isRequired,
    putIsLoading: PropTypes.bool.isRequired,
    services: PropTypes.arrayOf(PropTypes.string).isRequired
};

const mapStateToProps = ({organization, services}, props) => ({
    insufficientUsers: organization.insufficientUsers,
    loading: organization.loading,
    organization: organization.organization,
    organizationEmployees: organization.organizationEmployees,
    organizationUuid: props.match.params.uuid,
    putIsLoading: organization.putIsLoading,
    services: services.services
});

const mapDispatchToProps = (dispatch) => ({
    onClearOrganization: () => {
        dispatch(setOrganization(null))
    },
    onClearOrganizationEmployees: () => {
        dispatch(setOrganizationEmployees([]))
    },
    onGetInsufficientUsers: () => {
        dispatch(getInsufficientUsers())
    },
    onGetOrganizationEmployees: (uuid) => {
        dispatch(getOrganizationEmployees(uuid))
    },
    onGetOrganization: (uuid) => {
        dispatch(getOrganization(uuid))
    },
    onPutOrganization: (organization) => {
        dispatch(putOrganization(organization))
    },
    onSetHeaderText: (headerText) => {
        dispatch(setHeaderText(headerText))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Organization);
