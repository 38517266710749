
export default (state = {
    advancedPermissions: [],
    simplePermissions: []
}, action) => {
    switch (action.type) {
        case "PERMISSIONS_SET_ADVANCED_PERMISSIONS":
            return {
                ...state,
                advancedPermissions: action.permissions
            };
        case "PERMISSIONS_SET_SIMPLE_PERMISSIONS":
            return {
                ...state,
                simplePermissions: action.permissions
            };
        default:
            return state;
    }
};
