
export default (state = {
    profile: null
}, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                profile: action.data
            };
        default:
            return state;
    }
};
