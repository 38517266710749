import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Components/Tooltip";
import {
    inputClass,
    labelClass,
    mandatoryClass,
    otherMandatoryInputWithTooltipContainer,
    tooltipClass
} from "./Inputs.css";

const OtherMandatoryInputWithTooltip = ({ children, label, tooltipPlacement, tooltipText }) => (
    <div className={otherMandatoryInputWithTooltipContainer}>
        <label htmlFor="input" className={labelClass}>{label}</label>
        <p className={mandatoryClass}>{"Obligatorisk"}</p>
        <div className={tooltipClass}>
            <Tooltip placement={tooltipPlacement} text={tooltipText}/>
        </div>
        <div className={inputClass}>
            {children}
        </div>
    </div>
);

OtherMandatoryInputWithTooltip.propTypes = {
    children: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    tooltipPlacement: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired
};

export default OtherMandatoryInputWithTooltip;
