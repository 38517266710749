import React from "react";
import PropTypes from "prop-types";
import Icon from "../Components/Icon";
import {
    buttonDelete,
    deleteText,
    iconDelete,
    inlineStart
} from "./Buttons.css";

const DeleteButton = ({ buttonText, deleteHandler }) => (
    <div>
        <button
            className={buttonDelete}
            onClick={deleteHandler}
        >
            <div className={inlineStart}>
                <Icon
                    type={"slett"}
                    className={iconDelete}
                />
                <span className={deleteText}>{buttonText}</span>
            </div>
        </button>
    </div>
);

DeleteButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    deleteHandler: PropTypes.func.isRequired
};

export default DeleteButton;
