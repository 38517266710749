import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { loadAnnouncement, loadProfile } from "../actions/login";
import Layout from "./Layout/Layout";
import Login from "./Login/Login";
import PageNotFound from "./PageNotFound/PageNotFound";

class Routing extends PureComponent {

    componentDidMount() {
        this.props.onLoadProfile();
        this.props.onLoadAnnouncement();
    }

    render() {
        return (
            <Switch>
                <Route path="/inngang" component={Login} />
                <Route path={"/404"} component={PageNotFound} />
                <Route component={Layout} />
            </Switch>
        )
    }
}

Routing.propTypes = {
    onLoadAnnouncement: PropTypes.func.isRequired,
    onLoadProfile: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
    onLoadAnnouncement: () => {
        dispatch(loadAnnouncement())
    },
    onLoadProfile: () => {
        dispatch(loadProfile())
    }
});

export default connect(
    null,
    mapDispatchToProps
)(Routing);

