import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
    card,
    cardText,
    cardWrapper,
    labelText,
    listLabels,
    organizationItemGrid
} from "./Organizations.css";

class OrganizationList extends PureComponent {

    getServices = (organizationServices) => {
        let services = "";
        for (let i=0; i<organizationServices.length; i++) {
            if (i === organizationServices.length-1) {
                services += organizationServices[i];
            } else {
                services += `${organizationServices[i]} - `;
            }
        }
        return services;
    };

    render() {
        return (
            <div>
                <div className={listLabels}>
                    <div className={organizationItemGrid}>
                        <p className={labelText}>{"NAVN"}</p>
                        <p className={labelText}>{"E-POST"}</p>
                        <p className={labelText}>{"TELEFON"}</p>
                        <p className={labelText}>{"TJENESTER"}</p>
                    </div>
                </div>

                {this.props.organizations.map((organization) => (
                    <div className={cardWrapper} key={organization.uuid}>
                        <Link to={`/bedrifter/${organization.uuid}`} style={{textDecoration: 'none', outline: 0}}>
                            <div className={card}>
                                <div className={organizationItemGrid}>
                                    <p className={cardText}>{organization.name}</p>
                                    <p className={cardText} title={organization.email && organization.email.length > 20 ? organization.email : null}>{organization.email}</p>
                                    <p className={cardText}>{organization.phoneNumber}</p>
                                    <p className={cardText}>
                                        {organization.services ? this.getServices(organization.services) : null}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        )
    }
}

OrganizationList.propTypes = {
    organizations: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        services: PropTypes.array.isRequired
    })).isRequired
};

export default OrganizationList;
