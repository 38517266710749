import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setHeaderText } from "../../actions/layout";
import {
    container,
    box1,
    box2,
    box3,
    box4
} from "./InfoPage.css";

class InfoPage extends PureComponent {

    componentDidMount ()  {
        this.props.onSetHeaderText("Informasjon");
    }

    render() {
        return (
            <div className={container}>
                <div className={box1}>
                </div>
                <div className={box2}>
                </div>
                <div className={box3}>
                </div>
                <div className={box4}>
                </div>
            </div>
        )
    }
}

InfoPage.propTypes = {
    onSetHeaderText: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
    onSetHeaderText: (headerText) => {
        dispatch(setHeaderText(headerText))
    }
});

export default connect(
    null,
    mapDispatchToProps
)(InfoPage);
