import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Components/Tooltip";
import {
    inputClass,
    labelClass,
    otherInputWithTooltipContainer,
    tooltipClass
} from "./Inputs.css";

const OtherInputWithTooltip = ({ children, label, tooltipPlacement, tooltipText }) => (
    <div className={otherInputWithTooltipContainer}>
        <label htmlFor="input" className={labelClass}>{label}</label>
        <div className={tooltipClass}>
            <Tooltip placement={tooltipPlacement} text={tooltipText}/>
        </div>
        <div className={inputClass}>
            {children}
        </div>
    </div>
);

OtherInputWithTooltip.propTypes = {
    children: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    tooltipPlacement: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired
};

export default OtherInputWithTooltip;
