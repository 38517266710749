/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Icon from "../Components/Icon";
import {
    buttonLogout,
    iconLogout,
    inlineCenter
} from "./Buttons.css"

const LogoutButton = (props) =>(
    <button
        className={buttonLogout}
        onClick={props.onLogout}
        style={props.style}
    >
        <div className={inlineCenter}>
            <Icon type={"loggut"} className={iconLogout}/>
            {"Logg ut"}
        </div>
    </button>
);

LogoutButton.propTypes = {
    onLogout: PropTypes.func.isRequired,
    style: PropTypes.object
};

const mapDispatchToProps = () => ({
    onLogout: () => {
        window.location.href = `${API_URL}/service/authentication/goodbye?device=web`;
    }
});

export default connect(
    null,
    mapDispatchToProps
)(LogoutButton);
