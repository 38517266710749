import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { removeAnnouncement, removeConfirm, removeError, removeInformation } from "../../actions/errors";
import Announcement from "./Announcement";
import Confirm from "./Confirm";
import Error from "./Error";
import Information from "./Information";

class ErrorMessages extends PureComponent {

    componentDidUpdate(prevProps) {
        if (!prevProps.confirm && this.props.confirm) {
            document.getElementById('main').scrollTop = 0;
            setTimeout(() => this.props.onRemoveConfirm(), 10000);
        } else if (!prevProps.error && this.props.error) {
            document.getElementById('main').scrollTop = 0;
            setTimeout(() => this.props.onRemoveError(), 10000);
        } else if (!prevProps.information && this.props.information) {
            document.getElementById('main').scrollTop = 0;
            setTimeout(() => this.props.onRemoveInformation(), 10000);
        }
    }

    render() {
        return (
            <div>
                { this.props.announcement &&
                <Announcement
                    errorHeader={this.props.announcement}
                    errorMessage={this.props.announcementMessage}
                    removeError={this.props.onRemoveAnnouncement}
                />
                }
                { this.props.confirm &&
                <Confirm
                    errorHeader={this.props.confirm}
                    errorMessage={this.props.confirmMessage}
                    removeError={this.props.onRemoveConfirm}
                />
                }
                { this.props.error &&
                <Error
                    errorHeader={this.props.error}
                    errorMessage={this.props.errorMessage}
                    removeError={this.props.onRemoveError}
                />
                }
                { this.props.information &&
                <Information
                    errorHeader={this.props.information}
                    errorMessage={this.props.informationMessage}
                    removeError={this.props.onRemoveInformation}
                />
                }
            </div>
        )
    }
}

ErrorMessages.propTypes = {
    announcement: PropTypes.string,
    announcementMessage: PropTypes.string,
    confirm: PropTypes.string,
    confirmMessage: PropTypes.string,
    error: PropTypes.string,
    errorMessage: PropTypes.string,
    information: PropTypes.string,
    informationMessage: PropTypes.string,
    onRemoveAnnouncement: PropTypes.func.isRequired,
    onRemoveConfirm: PropTypes.func.isRequired,
    onRemoveError: PropTypes.func.isRequired,
    onRemoveInformation: PropTypes.func.isRequired
};

const mapStateToProps = ({ errors }) => ({
    announcement: errors.announcement,
    announcementMessage: errors.announcementMessage,
    confirm: errors.confirm,
    confirmMessage: errors.confirmMessage,
    error: errors.error,
    errorMessage: errors.errorMessage,
    information: errors.information,
    informationMessage: errors.informationMessage
});

const mapDispatchToProps = (dispatch) => ({
    onRemoveAnnouncement: () => {
        dispatch(removeAnnouncement())
    },
    onRemoveConfirm: () => {
        dispatch(removeConfirm())
    },
    onRemoveError: () => {
        dispatch(removeError())
    },
    onRemoveInformation: () => {
        dispatch(removeInformation())
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorMessages);
