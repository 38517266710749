import React from "react";
import ngulogo from "../../../resources/images/ngulogo.svg";
import {
    logocontainer,
    logostyle,
    logowrapper
} from "./Layout.css";

const Logo = () => (
    <div className={logocontainer}>
        <div className={logowrapper}>
            <img
                className={logostyle}
                src={ngulogo}
                alt="Logo"
            />
        </div>
    </div>
);

export default Logo;
