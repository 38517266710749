/* eslint-disable no-undef */
import {setConfirm, setError, setInformation} from "./errors";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const setDeleteUserIsLoading = (loading) => ({
    type: "USER_SET_DELETE_IS_LOADING",
    loading
});

const setPutUserIsLoading = (loading) => ({
    type: "USER_SET_PUT_IS_LOADING",
    loading
});

const setOrganizations = (organizations) => ({
    type: "USER_SET_ORGANIZATIONS",
    organizations
});

const setUserIsLoading = (loading) => ({
    type: "USER_SET_LOADING",
    loading
});

export const setUser = (user) => ({
    type: "USER_SET_USER",
    user
});

export const getUser = (uuid) => (dispatch) => new Promise(() => {
    dispatch(setUserIsLoading(true));
    fetch(`${API_URL}/account/user/${uuid}`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setUser(data));
            dispatch(setUserIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            dispatch(setUserIsLoading(false));
            return error;
        })
});
export const getOrganizations = () => (dispatch) => {
    fetch(`${API_URL}/account/organization`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setOrganizations(data));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            return error;
        })
};

export const putUser = (user) => (dispatch) => new Promise(() => {
    dispatch(setPutUserIsLoading(true));
    fetch(`${API_URL}/account/user/${user.uuid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setUser(data));
            dispatch(setConfirm("Brukeren er oppdatert", `Bruker ${user.name} er oppdatert`));
            dispatch(setPutUserIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en ukjent feil", "Prøv igjen eller meld fra om feil"));
            dispatch(setPutUserIsLoading(false));
            return error;
        })
});

export const deleteUser = (user) => (dispatch) => new Promise(() => {
    dispatch(setDeleteUserIsLoading(true));
    fetch(`${API_URL}/account/user/${user.uuid}`, {
        method: "DELETE",
        credentials: "include"
    })
        .then(response => {
            if (!response.ok) {
                if (response.status === 409) {
                    dispatch(setError("Kunne ikke slette bruker", "Det er ikke mulig å slette eier av bedrift"));
                } else {
                    dispatch(setError("Det har skjedd en ukjent feil", "Prøv igjen eller meld fra om feil"));
                }
                throw Error(response.statusText);
            }
            return response;
        })
        .then(response => response.json())
        .then(data => {
            dispatch(setUser(data));
            dispatch(setInformation("Brukeren er slettet", `Bruker ${data.name} er slettet`));
            dispatch(setDeleteUserIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setDeleteUserIsLoading(false));
            return error;
        })
});
