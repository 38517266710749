import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setHeaderText} from "../../actions/layout";
import {getOrganizations, searchOrganizations, setShowCreateNewOrganizationModal} from "../../actions/organizations";
import ActionButton from "../Buttons/ActionButton";
import Loader from "../Components/Loader";
import NewOrganization from "./NewOrganization/NewOrganization";
import OrganizationList from "./OrganizationList";
import Search from "../Components/Search";
import SystemFilters from "./SystemFilters";
import {
    button1,
    filter1,
    flexBoxRowStart,
    loader,
    mainHeader,
    organizationsGrid,
    orgList,
    search
} from "./Organizations.css";

class Organizations extends PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount ()  {
        this.props.onSetHeaderText("Bedrifter");
        this.props.onGetOrganizations(this.props.filter);
    }

    componentDidUpdate (prevProps) {
        if (prevProps.showCreateNewOrganizationModal && !this.props.showCreateNewOrganizationModal) {
            this.props.onGetOrganizations(this.props.filter);
        }
    }

    onSetSystemFilter = (filter) => {
        this.props.onGetOrganizations(filter);
    };

    onSearch = (query) => {
        this.props.onSearchOrganizations(query);
    };

    onCreateNewOrganization = () => {
        this.props.onSetShowCreateNewOrganizationModal(true);
    };

    onCancelCreateNewOrganization = () => {
        this.props.onSetShowCreateNewOrganizationModal(false);
    };

    render() {
        return (
            <div>
                <div className={organizationsGrid}>
                    <div className={button1}>
                        <ActionButton
                            buttonText={"+ Legg til ny bedrift"}
                            onClick={this.onCreateNewOrganization}
                            style={{width: "240px"}}
                        />
                    </div>

                    <div className={filter1} style={{marginTop: "10px"}}>
                        <SystemFilters
                            onSetSystemFilter={this.onSetSystemFilter}
                            systemFilter={this.props.filter}
                        />
                    </div>

                    <div className={search}>
                        <Search
                            handleSearch={this.onSearch}
                            placeholder={"Søk etter bedrift på navn"}
                        />
                    </div>

                    <div className={mainHeader}>
                        <div className={flexBoxRowStart}>
                            <h2>{"Bedrifter"}</h2>
                            {this.props.loading &&
                                <div className={loader}>
                                    <Loader color={"#434f5a"}/>
                                </div>
                            }
                        </div>
                    </div>

                    <div className={orgList}>
                        <OrganizationList
                            organizations={this.props.organizations}
                        />
                    </div>
                </div>

                {this.props.showCreateNewOrganizationModal &&
                    <NewOrganization
                        onCancel={this.onCancelCreateNewOrganization}
                    />
                }
            </div>
        )
    }
}

Organizations.propTypes = {
    filter: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    onGetOrganizations: PropTypes.func.isRequired,
    onSearchOrganizations: PropTypes.func.isRequired,
    onSetHeaderText: PropTypes.func.isRequired,
    onSetShowCreateNewOrganizationModal: PropTypes.func.isRequired,
    organizations: PropTypes.array.isRequired,
    showCreateNewOrganizationModal: PropTypes.bool.isRequired,
    systemFilter: PropTypes.string
};

const mapStateToProps = ({organizations}) => ({
    filter: organizations.filter,
    loading: organizations.loading,
    organizations: organizations.organizations,
    showCreateNewOrganizationModal: organizations.showCreateNewOrganizationModal
});

const mapDispatchToProps = (dispatch) => ({
    onGetOrganizations: (filter) => {
        dispatch(getOrganizations(filter))
    },
    onSearchOrganizations: (query) => {
        dispatch(searchOrganizations(query))
    },
    onSetHeaderText: (headerText) => {
        dispatch(setHeaderText(headerText))
    },
    onSetShowCreateNewOrganizationModal: (show) => {
        dispatch(setShowCreateNewOrganizationModal(show))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Organizations);
