import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setHeaderText} from "../../actions/layout";
import {getUsers} from "../../actions/users";
import Loader from "../Components/Loader";
import Search from "../Components/Search";
import UserList from "./UserList";
import {
    flexBoxRowStart,
    loader,
    mainHeader,
    search,
    userList,
    usersGrid
} from "./Users.css";

class Users extends PureComponent {

    componentDidMount ()  {
        this.props.onSetHeaderText("Brukere");
    }

    onSearch = (query) => {
        this.props.onGetUsers(query);
    };

    render() {
        return (
            <div className={usersGrid}>
                <div className={search}>
                    <Search
                        handleSearch={this.onSearch}
                        placeholder={"Søk etter navn på bruker"}
                    />
                </div>

                {(this.props.users.length > 0 || this.props.loading) &&
                <div className={mainHeader}>
                    <div className={flexBoxRowStart}>
                        <h2>{"Brukere"}</h2>
                        {this.props.loading &&
                        <div className={loader}>
                            <Loader color={"#434f5a"}/>
                        </div>
                        }
                    </div>
                </div>
                }

                {this.props.users.length > 0 &&
                    <div className={userList}>
                        <UserList
                            users={this.props.users}
                        />
                    </div>
                }
            </div>
        )
    }
}

Users.propTypes = {
    loading: PropTypes.bool.isRequired,
    onGetUsers: PropTypes.func.isRequired,
    onSetHeaderText: PropTypes.func.isRequired,
    systemFilter: PropTypes.string,
    users: PropTypes.array.isRequired
};

const mapStateToProps = ({users}) => ({
    loading: users.loading,
    users: users.users
});

const mapDispatchToProps = (dispatch) => ({
    onGetUsers: (query) => {
        dispatch(getUsers(query))
    },
    onSetHeaderText: (headerText) => {
        dispatch(setHeaderText(headerText))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);
