/* eslint-disable no-undef */
import {setError} from "./errors";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const setAdvancedPermissions = (permissions) => ({
    type: "PERMISSIONS_SET_ADVANCED_PERMISSIONS",
    permissions
});

const setSimplePermissions = (permissions) => ({
    type: "PERMISSIONS_SET_SIMPLE_PERMISSIONS",
    permissions
});

export const getAdvancedPermissions = () => (dispatch) => {
    fetch(`${API_URL}/account/permissions/advanced`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setAdvancedPermissions(data));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            return error;
        })
};

export const getSimplePermissions = () => (dispatch) => {
    fetch(`${API_URL}/account/permissions/simple`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setSimplePermissions(data));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            return error;
        })
};
