
import admin from "./reducers/admin";
import errors from "./reducers/errors";
import event from "./reducers/event";
import events from "./reducers/events";
import layout from "./reducers/layout";
import organization from "./reducers/organization";
import organizations from "./reducers/organizations";
import permissions from "./reducers/permissions";
import services from "./reducers/services";
import user from "./reducers/user";
import users from "./reducers/users";

export const reducers = {
    admin,
    errors,
    event,
    events,
    layout,
    organization,
    organizations,
    permissions,
    services,
    user,
    users
};
