import React from "react";
import PropTypes from "prop-types";
import glyphs from "../../../resources/glyphs.json";

const icons = glyphs.icons.reduce((memo, glyph) => {
    memo[glyph.properties.name] = glyph.icon;
    return memo;
}, {});

const Icon = ({ className, type }) => {

    const glyph = icons[type];

    return (
        <svg
            className={className}
            viewBox="0 0 1024 1024"
        >
            {glyph.paths.map((path) => (
                <path
                    d={path}
                    key={path}
                />
            ))}
        </svg>
    );
};

Icon.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired
};

Icon.defaultProps = {className: ""};

export default Icon;
