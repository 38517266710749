import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getEvent, setEvent} from "../../../actions/event";
import {setHeaderText} from "../../../actions/layout";
import {Link} from "react-router-dom";
import LoaderBig from "../../Components/LoaderBig";
import {
    eventGrid,
    generalInfoGrid,
    header1,
    input1,
    input2,
    input3,
    input4,
    label1,
    label2,
    label3,
    label4,
    valueGrid
} from "./Event.css";
import SmallActionButton from "../../Buttons/SmallActionButton";

class Event extends PureComponent {

    componentDidMount()  {
        this.props.onSetHeaderText("Logg");
        this.props.onGetEvent(this.props.eventUuid);
    }

    componentWillUnmount() {
        this.props.onClearEvent();
    }

    render() {
        return (
            <div>
                {this.props.loading &&
                    <LoaderBig/>
                }

                <div className={eventGrid}>
                    <div className={header1}>
                        <h2>{"Logg " + this.props.eventUuid}</h2>
                    </div>

                    <div className={input1}>
                        <div className={generalInfoGrid}>
                            <div className={label1}>{"Action:"}</div>
                            <p className={input1}>{this.props.event ? this.props.event.action : null}</p>
                            <div className={label2}>{"Endepunkt:"}</div>
                            <p className={input2}>{this.props.event ? this.props.event.endpoint : null}</p>
                            <div className={label3}>{"Tjeneste:"}</div>
                            <p className={input3}>{this.props.event ? this.props.event.service : null}</p>
                            <div className={label4}>{"Dato:"}</div>
                            <p className={input4}>{this.props.event ? this.props.event.created : null}</p>
                        </div>
                    </div>
                    
                    <div className={input2}>
                        <Link to={`/brukere/${this.props.event ? this.props.event.createdBy : null}`} style={{textDecoration: 'none', outline: 0}}>
                            <SmallActionButton buttonText={`Utført av bruker ${this.props.event ? this.props.event.createdBy : null}`}/>
                        </Link>
                    </div>

                    <div className={input3}>
                        <div className={valueGrid}>
                            <div className={label1}>{"Fra verdi:"}</div>
                            <div className={input1}>
                                <textarea
                                    rows={12}
                                    value={this.props.event ? this.props.event.fromValue ? this.props.event.fromValue : "" : ""}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={input4}>
                        <div className={valueGrid}>
                            <div className={label1}>{"Til verdi:"}</div>
                            <div className={input1}>
                                <textarea
                                    rows={12}
                                    value={this.props.event ? this.props.event.toValue ? this.props.event.toValue : "" : ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Event.propTypes = {
    event: PropTypes.shape({
        action: PropTypes.string,
        created: PropTypes.string,
        createdBy: PropTypes.string,
        endpoint: PropTypes.string,
        fromValue: PropTypes.string,
        toValue: PropTypes.string,
        service: PropTypes.string
    }),
    eventUuid: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    onClearEvent: PropTypes.func.isRequired,
    onGetEvent: PropTypes.func.isRequired,
    onSetHeaderText: PropTypes.func.isRequired
};

const mapStateToProps = ({event}, props) => ({
    event: event.event,
    eventUuid: props.match.params.uuid,
    loading: event.loading
});

const mapDispatchToProps = (dispatch) => ({
    onClearEvent: () => {
        dispatch(setEvent(null))
    },
    onGetEvent: (uuid) => {
        dispatch(getEvent(uuid))
    },
    onSetHeaderText: (headerText) => {
        dispatch(setHeaderText(headerText))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Event);
