import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    card,
    cardText,
    cardWrapper,
    labelText,
    listLabels,
    userItemGrid
} from "./Users.css";

class UserList extends PureComponent {
    render() {
        return (
            <div>
                <div className={listLabels}>
                    <div className={userItemGrid}>
                        <p className={labelText}>{"NAVN"}</p>
                        <p className={labelText}>{"E-POST"}</p>
                        <p className={labelText}>{"STATUS"}</p>
                        <p className={labelText}>{"BEDRIFT"}</p>
                    </div>
                </div>

                {this.props.users.map((user) => (
                    <div className={cardWrapper} key={user.uuid}>
                        <Link to={`/brukere/${user.uuid}`} style={{textDecoration: 'none', outline: 0}}>
                            <div className={card}>
                                <div className={userItemGrid}>
                                    <p className={cardText}>{user.name}</p>
                                    <p className={cardText} title={user.email && user.email.length > 20 ? user.email : null}>{user.email}</p>
                                    <p className={cardText}>{user.status}</p>
                                    <p className={cardText}>{user.organization ? user.organization.name : null}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        )
    }
}

UserList.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string,
        email: PropTypes.string,
        status: PropTypes.string.isRequired,
        organization: PropTypes.shape({
            name: PropTypes.string.isRequired
        })
    })).isRequired
};

export default UserList;
