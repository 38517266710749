
export default (state = {
    events: [],
    loading: false
}, action) => {
    switch (action.type) {
        case "EVENTS_SET_EVENTS":
            return {
                ...state,
                events: action.events
            };
        case "EVENTS_SET_LOADING":
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;
    }
};
