
export default (state = {
    announcement: null,
    announcementMessage: null,
    confirm: null,
    confirmMessage: null,
    error: null,
    errorMessage: null,
    information: null,
    informationMessage: null,
    modalError: null
}, action) => {
    switch (action.type) {
        case 'REMOVE_ANNOUNCEMENT':
            return {
                ...state,
                announcement: null,
                announcementMessage: null
            };
        case 'REMOVE_CONFIRM':
            return {
                ...state,
                confirm: null,
                confirmMessage: null
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                error: null,
                errorMessage: null
            };
        case 'REMOVE_INFORMATION':
            return {
                ...state,
                information: null,
                informationMessage: null
            };
        case 'REMOVE_MODAL_ERROR':
            return {
                ...state,
                modalError: null
            };
        case 'SET_ANNOUNCEMENT':
            return {
                ...state,
                announcement: action.announcement,
                announcementMessage: action.announcementMessage
            };
        case 'SET_CONFIRM':
            return {
                ...state,
                confirm: action.confirm,
                confirmMessage: action.confirmMessage
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.error,
                errorMessage: action.errorMessage
            };
        case 'SET_INFORMATION':
            return {
                ...state,
                information: action.information,
                informationMessage: action.informationMessage
            };
        case 'SET_MODAL_ERROR':
            return {
                ...state,
                modalError: action.error
            };
        default:
            return state;
    }
};
