import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    headercontainer,
    header
} from "./Layout.css";

const Header = ({ headerText }) => {
    return (
        <div className={headercontainer}>
            <h1 className={header}>
                {headerText}
            </h1>
        </div>
    );
};

Header.propTypes = {
    headerText: PropTypes.string.isRequired
};

const mapStateToProps = ({ layout }) => ({
    headerText: layout.headerText
});

export default connect(mapStateToProps)(Header);
