import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
    card,
    cardText,
    cardWrapper,
    eventItemGrid,
    labelText,
    tableLabels
} from "./Log.css";

class LogTable extends PureComponent {

    render() {

        if (this.props.events.length === 0) {
            return null;
        }

        return (
            <div>
                <div className={tableLabels}>
                    <div className={eventItemGrid}>
                        <p className={labelText}>{"ACTION"}</p>
                        <p className={labelText}>{"ENDEPUNKT"}</p>
                        <p className={labelText}>{"TJENESTE"}</p>
                        <p className={labelText}>{"DATO"}</p>
                        <p className={labelText}>{"UTFØRT AV"}</p>
                    </div>
                </div>

                {this.props.events.map((event) => (
                    <div className={cardWrapper} key={event.uuid}>
                        <Link to={`/logg/${event.uuid}`} style={{textDecoration: 'none', outline: 0}}>
                            <div className={card}>
                                <div className={eventItemGrid}>
                                    <p className={cardText}>{event.action}</p>
                                    <p className={cardText}>{event.endpoint}</p>
                                    <p className={cardText}>{event.service}</p>
                                    <p className={cardText}>{event.created}</p>
                                    <p className={cardText}>{event.createdBy}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        )
    }
}

LogTable.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        action: PropTypes.string,
        created: PropTypes.string,
        createdBy: PropTypes.string,
        endpoint: PropTypes.string,
        service: PropTypes.string
    })).isRequired
};

export default LogTable;
