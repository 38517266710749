
/*
* Simple component needed for hot loader to work correctly.
* index.web.jsx sets up application state and context and cannot be hot loaded.
* The next component down Routing.jsx is a connected component and cannot be "hot" exported.
* */

import React from "react";
import {hot} from "react-hot-loader";
import {Route, Switch} from "react-router";
import Routing from "./components/Routing";

class RootComponent extends React.Component {
    render() {
       
        return (
            <Switch>
                <Route component={Routing}/>
            </Switch>
        )
    }
}

export default hot(module)(RootComponent);
