import React from "react";
import PropTypes from "prop-types";
import Icon from "../Components/Icon";
import {
    announcementBox,
    errorContent,
    errorIconWhite,
    headerContent,
    headerTextWhite,
    messageContent,
    messageTextWhite,
    removeButton,
    removeIconWhite
} from "./Error.css";

const Announcement = ({ errorHeader, errorMessage, removeError }) => {
    return (
        <div className={announcementBox}>
            <div className={errorContent}>
                <Icon
                    type={"informasjon"}
                    className={errorIconWhite}
                />
                <div className={headerContent}>
                    <div className={headerTextWhite}>
                        {errorHeader}
                    </div>
                </div>
                <div className={messageContent}>
                    <div className={messageTextWhite}>
                        {errorMessage}
                    </div>
                </div>
                <button
                    className={removeButton}
                    onClick={() => removeError()}>
                    <Icon
                        type={"kryss"}
                        className={removeIconWhite}
                    />
                </button>
            </div>
        </div>
    );
};

Announcement.propTypes = {
    errorHeader: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    removeError: PropTypes.func.isRequired
};

export default Announcement;
